import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_4ec88b3c from 'nuxt_plugin_sentryserver_4ec88b3c' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_2de1cc98 from 'nuxt_plugin_sentryclient_2de1cc98' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_pluginrouting_3d36324e from 'nuxt_plugin_pluginrouting_3d36324e' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_39228be1 from 'nuxt_plugin_pluginmain_39228be1' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_vlazyload_d1213334 from 'nuxt_plugin_vlazyload_d1213334' // Source: ./v-lazy-load.js (mode: 'all')
import nuxt_plugin_clipboard_7a27aa50 from 'nuxt_plugin_clipboard_7a27aa50' // Source: ./clipboard.js (mode: 'client')
import nuxt_plugin_vuesocialsharingplugin_086e9271 from 'nuxt_plugin_vuesocialsharingplugin_086e9271' // Source: ./vue-social-sharing-plugin.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_412d19c0 from 'nuxt_plugin_cookieuniversalnuxt_412d19c0' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_nuxtmq_3ff7c680 from 'nuxt_plugin_nuxtmq_3ff7c680' // Source: ./nuxt-mq.js (mode: 'all')
import nuxt_plugin_axios_40b5c378 from 'nuxt_plugin_axios_40b5c378' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_gtm_687def64 from 'nuxt_plugin_gtm_687def64' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_moment_36a385c0 from 'nuxt_plugin_moment_36a385c0' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_gtm_5e4639ea from 'nuxt_plugin_gtm_5e4639ea' // Source: ../plugins/gtm (mode: 'all')
import nuxt_plugin_yjaxios_46332430 from 'nuxt_plugin_yjaxios_46332430' // Source: ../plugins/yj-axios (mode: 'all')
import nuxt_plugin_appservice_baa29c5a from 'nuxt_plugin_appservice_baa29c5a' // Source: ../plugins/app-service (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_i18n_6a80ea94 from 'nuxt_plugin_i18n_6a80ea94' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_elementui_a6a1b20a from 'nuxt_plugin_elementui_a6a1b20a' // Source: ../plugins/element-ui (mode: 'all')
import nuxt_plugin_filters_2dd71012 from 'nuxt_plugin_filters_2dd71012' // Source: ../plugins/filters.js (mode: 'all')
import nuxt_plugin_vueselect_0c6a19ce from 'nuxt_plugin_vueselect_0c6a19ce' // Source: ../plugins/vue-select (mode: 'all')
import nuxt_plugin_vueawesomeswiper_4c7fbf9a from 'nuxt_plugin_vueawesomeswiper_4c7fbf9a' // Source: ../plugins/vue-awesome-swiper.js (mode: 'all')
import nuxt_plugin_scrollactive_63655025 from 'nuxt_plugin_scrollactive_63655025' // Source: ../plugins/scroll-active.js (mode: 'all')
import nuxt_plugin_vueclosable_a5061116 from 'nuxt_plugin_vueclosable_a5061116' // Source: ../plugins/vue-closable.js (mode: 'client')
import nuxt_plugin_plugin_0c4a56b4 from 'nuxt_plugin_plugin_0c4a56b4' // Source: ./auth/plugin.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule
const baseStoreOptions = { preserveState: process.client }

function registerModule (path, rawModule, options = {}) {
  return originalRegisterModule.call(this, path, rawModule, { ...baseStoreOptions, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap","defer":true}],"script":[{"src":"\u002Fjs\u002Fjquery-3.2.1.min.js","type":"text\u002Fjavascript","defer":true},{"src":"\u002Fjs\u002Fbootstrap-slider.min.js","type":"text\u002Fjavascript","defer":true},{"src":"\u002Fjs\u002Fhistogram-slider.js","type":"text\u002Fjavascript","defer":true},{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function (w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e].p&&e[e][p]()))?1:0})(window,'navigator','doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.defer=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"style":[],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":""}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_4ec88b3c === 'function') {
    await nuxt_plugin_sentryserver_4ec88b3c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_2de1cc98 === 'function') {
    await nuxt_plugin_sentryclient_2de1cc98(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_3d36324e === 'function') {
    await nuxt_plugin_pluginrouting_3d36324e(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_39228be1 === 'function') {
    await nuxt_plugin_pluginmain_39228be1(app.context, inject)
  }

  if (typeof nuxt_plugin_vlazyload_d1213334 === 'function') {
    await nuxt_plugin_vlazyload_d1213334(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clipboard_7a27aa50 === 'function') {
    await nuxt_plugin_clipboard_7a27aa50(app.context, inject)
  }

  if (typeof nuxt_plugin_vuesocialsharingplugin_086e9271 === 'function') {
    await nuxt_plugin_vuesocialsharingplugin_086e9271(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_412d19c0 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_412d19c0(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtmq_3ff7c680 === 'function') {
    await nuxt_plugin_nuxtmq_3ff7c680(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_40b5c378 === 'function') {
    await nuxt_plugin_axios_40b5c378(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_687def64 === 'function') {
    await nuxt_plugin_gtm_687def64(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_36a385c0 === 'function') {
    await nuxt_plugin_moment_36a385c0(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_5e4639ea === 'function') {
    await nuxt_plugin_gtm_5e4639ea(app.context, inject)
  }

  if (typeof nuxt_plugin_yjaxios_46332430 === 'function') {
    await nuxt_plugin_yjaxios_46332430(app.context, inject)
  }

  if (typeof nuxt_plugin_appservice_baa29c5a === 'function') {
    await nuxt_plugin_appservice_baa29c5a(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_6a80ea94 === 'function') {
    await nuxt_plugin_i18n_6a80ea94(app.context, inject)
  }

  if (typeof nuxt_plugin_elementui_a6a1b20a === 'function') {
    await nuxt_plugin_elementui_a6a1b20a(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_2dd71012 === 'function') {
    await nuxt_plugin_filters_2dd71012(app.context, inject)
  }

  if (typeof nuxt_plugin_vueselect_0c6a19ce === 'function') {
    await nuxt_plugin_vueselect_0c6a19ce(app.context, inject)
  }

  if (typeof nuxt_plugin_vueawesomeswiper_4c7fbf9a === 'function') {
    await nuxt_plugin_vueawesomeswiper_4c7fbf9a(app.context, inject)
  }

  if (typeof nuxt_plugin_scrollactive_63655025 === 'function') {
    await nuxt_plugin_scrollactive_63655025(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueclosable_a5061116 === 'function') {
    await nuxt_plugin_vueclosable_a5061116(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_0c4a56b4 === 'function') {
    await nuxt_plugin_plugin_0c4a56b4(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, (err) => {
        // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
        if (!err._isRouter) return reject(err)
        if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
