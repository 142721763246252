export default ($axios, $yjAxios) => ({
  validateUserInfo(payload) {
    return $axios.post('/auth/validate-user', payload)
  },
  sendEmailVerificationCode(payload) {
    return $axios.post('/auth/generate-email-authentication-code', payload)
  },
  verifyAuthenticationCode(payload) {
    return $axios.post('/auth/verify-email-authentication-code', payload)
  },
  register(payload) {
    return $axios.post('/auth/register', payload)
  },
  validateFacebookID(payload) {
    return $axios.post('/auth/validate-facebook-id', payload)
  },
  forgotPassword(payload) {
    return $axios.post('/auth/forgot-password', payload)
  },
  loginByJwt() {
    return $axios.get('/auth/login-by-jwt', { withCredentials: true })
  },
  me() {
    return $axios.get('/auth/user')
  },
  createJwt(payload) {
    return $yjAxios.post('/recruit/login_by_password_ajax', payload, {
      withCredentials: true
    })
  },
  createJwtFacebook(payload) {
    return $yjAxios.post('/recruit/login/facebook/ajax', payload, {
      withCredentials: true
    })
  },
  deleteJwt() {
    return $yjAxios.post('/recruit/logout', {}, { withCredentials: true })
  },
  refreshJwt() {
    return $yjAxios.post('/recruit/refresh', {}, { withCredentials: true })
  },
  refreshAccessToken(jwtCookie) {
    const config = jwtCookie
      ? {
          headers: {
            cookie: jwtCookie
          }
        }
      : {
          withCredentials: true
        }

    return $axios.get('/auth/refresh-access-token', config)
  }
})
