import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2ec2c678 = () => interopDefault(import('../pages/all/index.vue' /* webpackChunkName: "pages/all/index" */))
const _5fbd8934 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6b2749ad = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _d11abf8a = () => interopDefault(import('../pages/favorite/index.vue' /* webpackChunkName: "pages/favorite/index" */))
const _ec782966 = () => interopDefault(import('../pages/flow/index.vue' /* webpackChunkName: "pages/flow/index" */))
const _221260e4 = () => interopDefault(import('../pages/forgot_password/index.vue' /* webpackChunkName: "pages/forgot_password/index" */))
const _a96700aa = () => interopDefault(import('../pages/history/index.vue' /* webpackChunkName: "pages/history/index" */))
const _0a36fdc4 = () => interopDefault(import('../pages/inquiry/index.vue' /* webpackChunkName: "pages/inquiry/index" */))
const _69860ebd = () => interopDefault(import('../pages/list/index.vue' /* webpackChunkName: "pages/list/index" */))
const _993c5980 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _05d12a9c = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _0818aefc = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _b6cd5ae8 = () => interopDefault(import('../pages/sitemap/index.vue' /* webpackChunkName: "pages/sitemap/index" */))
const _131ac044 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _094e7017 = () => interopDefault(import('../pages/inquiry/completed.vue' /* webpackChunkName: "pages/inquiry/completed" */))
const _15f3457b = () => interopDefault(import('../pages/register/completed.vue' /* webpackChunkName: "pages/register/completed" */))
const _46f71ccc = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _c7b37ec8 = () => interopDefault(import('../pages/property/_id.vue' /* webpackChunkName: "pages/property/_id" */))
const _6bfe9cab = () => interopDefault(import('../pages/_prefecture/index.vue' /* webpackChunkName: "pages/_prefecture/index" */))
const _321de458 = () => interopDefault(import('../pages/_prefecture/lines.vue' /* webpackChunkName: "pages/_prefecture/lines" */))
const _44a99dd5 = () => interopDefault(import('../pages/_prefecture/list.vue' /* webpackChunkName: "pages/_prefecture/list" */))
const _5a55b2f6 = () => interopDefault(import('../pages/_prefecture/stations.vue' /* webpackChunkName: "pages/_prefecture/stations" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/all",
    component: _2ec2c678,
    name: "all___ja"
  }, {
    path: "/en",
    component: _5fbd8934,
    name: "index___en"
  }, {
    path: "/faq",
    component: _6b2749ad,
    name: "faq___ja"
  }, {
    path: "/favorite",
    component: _d11abf8a,
    name: "favorite___ja"
  }, {
    path: "/flow",
    component: _ec782966,
    name: "flow___ja"
  }, {
    path: "/forgot_password",
    component: _221260e4,
    name: "forgot_password___ja"
  }, {
    path: "/history",
    component: _a96700aa,
    name: "history___ja"
  }, {
    path: "/inquiry",
    component: _0a36fdc4,
    name: "inquiry___ja"
  }, {
    path: "/ko",
    component: _5fbd8934,
    name: "index___ko"
  }, {
    path: "/list",
    component: _69860ebd,
    name: "list___ja"
  }, {
    path: "/login",
    component: _993c5980,
    name: "login___ja"
  }, {
    path: "/news",
    component: _05d12a9c,
    name: "news___ja"
  }, {
    path: "/pt",
    component: _5fbd8934,
    name: "index___pt"
  }, {
    path: "/register",
    component: _0818aefc,
    name: "register___ja"
  }, {
    path: "/sitemap",
    component: _b6cd5ae8,
    name: "sitemap___ja"
  }, {
    path: "/terms",
    component: _131ac044,
    name: "terms___ja"
  }, {
    path: "/vi",
    component: _5fbd8934,
    name: "index___vi"
  }, {
    path: "/zh",
    component: _5fbd8934,
    name: "index___zh"
  }, {
    path: "/en/all",
    component: _2ec2c678,
    name: "all___en"
  }, {
    path: "/en/faq",
    component: _6b2749ad,
    name: "faq___en"
  }, {
    path: "/en/favorite",
    component: _d11abf8a,
    name: "favorite___en"
  }, {
    path: "/en/flow",
    component: _ec782966,
    name: "flow___en"
  }, {
    path: "/en/forgot_password",
    component: _221260e4,
    name: "forgot_password___en"
  }, {
    path: "/en/history",
    component: _a96700aa,
    name: "history___en"
  }, {
    path: "/en/inquiry",
    component: _0a36fdc4,
    name: "inquiry___en"
  }, {
    path: "/en/list",
    component: _69860ebd,
    name: "list___en"
  }, {
    path: "/en/login",
    component: _993c5980,
    name: "login___en"
  }, {
    path: "/en/news",
    component: _05d12a9c,
    name: "news___en"
  }, {
    path: "/en/register",
    component: _0818aefc,
    name: "register___en"
  }, {
    path: "/en/sitemap",
    component: _b6cd5ae8,
    name: "sitemap___en"
  }, {
    path: "/en/terms",
    component: _131ac044,
    name: "terms___en"
  }, {
    path: "/inquiry/completed",
    component: _094e7017,
    name: "inquiry-completed___ja"
  }, {
    path: "/ko/all",
    component: _2ec2c678,
    name: "all___ko"
  }, {
    path: "/ko/faq",
    component: _6b2749ad,
    name: "faq___ko"
  }, {
    path: "/ko/favorite",
    component: _d11abf8a,
    name: "favorite___ko"
  }, {
    path: "/ko/flow",
    component: _ec782966,
    name: "flow___ko"
  }, {
    path: "/ko/forgot_password",
    component: _221260e4,
    name: "forgot_password___ko"
  }, {
    path: "/ko/history",
    component: _a96700aa,
    name: "history___ko"
  }, {
    path: "/ko/inquiry",
    component: _0a36fdc4,
    name: "inquiry___ko"
  }, {
    path: "/ko/list",
    component: _69860ebd,
    name: "list___ko"
  }, {
    path: "/ko/login",
    component: _993c5980,
    name: "login___ko"
  }, {
    path: "/ko/news",
    component: _05d12a9c,
    name: "news___ko"
  }, {
    path: "/ko/register",
    component: _0818aefc,
    name: "register___ko"
  }, {
    path: "/ko/sitemap",
    component: _b6cd5ae8,
    name: "sitemap___ko"
  }, {
    path: "/ko/terms",
    component: _131ac044,
    name: "terms___ko"
  }, {
    path: "/pt/all",
    component: _2ec2c678,
    name: "all___pt"
  }, {
    path: "/pt/faq",
    component: _6b2749ad,
    name: "faq___pt"
  }, {
    path: "/pt/favorite",
    component: _d11abf8a,
    name: "favorite___pt"
  }, {
    path: "/pt/flow",
    component: _ec782966,
    name: "flow___pt"
  }, {
    path: "/pt/forgot_password",
    component: _221260e4,
    name: "forgot_password___pt"
  }, {
    path: "/pt/history",
    component: _a96700aa,
    name: "history___pt"
  }, {
    path: "/pt/inquiry",
    component: _0a36fdc4,
    name: "inquiry___pt"
  }, {
    path: "/pt/list",
    component: _69860ebd,
    name: "list___pt"
  }, {
    path: "/pt/login",
    component: _993c5980,
    name: "login___pt"
  }, {
    path: "/pt/news",
    component: _05d12a9c,
    name: "news___pt"
  }, {
    path: "/pt/register",
    component: _0818aefc,
    name: "register___pt"
  }, {
    path: "/pt/sitemap",
    component: _b6cd5ae8,
    name: "sitemap___pt"
  }, {
    path: "/pt/terms",
    component: _131ac044,
    name: "terms___pt"
  }, {
    path: "/register/completed",
    component: _15f3457b,
    name: "register-completed___ja"
  }, {
    path: "/vi/all",
    component: _2ec2c678,
    name: "all___vi"
  }, {
    path: "/vi/faq",
    component: _6b2749ad,
    name: "faq___vi"
  }, {
    path: "/vi/favorite",
    component: _d11abf8a,
    name: "favorite___vi"
  }, {
    path: "/vi/flow",
    component: _ec782966,
    name: "flow___vi"
  }, {
    path: "/vi/forgot_password",
    component: _221260e4,
    name: "forgot_password___vi"
  }, {
    path: "/vi/history",
    component: _a96700aa,
    name: "history___vi"
  }, {
    path: "/vi/inquiry",
    component: _0a36fdc4,
    name: "inquiry___vi"
  }, {
    path: "/vi/list",
    component: _69860ebd,
    name: "list___vi"
  }, {
    path: "/vi/login",
    component: _993c5980,
    name: "login___vi"
  }, {
    path: "/vi/news",
    component: _05d12a9c,
    name: "news___vi"
  }, {
    path: "/vi/register",
    component: _0818aefc,
    name: "register___vi"
  }, {
    path: "/vi/sitemap",
    component: _b6cd5ae8,
    name: "sitemap___vi"
  }, {
    path: "/vi/terms",
    component: _131ac044,
    name: "terms___vi"
  }, {
    path: "/zh/all",
    component: _2ec2c678,
    name: "all___zh"
  }, {
    path: "/zh/faq",
    component: _6b2749ad,
    name: "faq___zh"
  }, {
    path: "/zh/favorite",
    component: _d11abf8a,
    name: "favorite___zh"
  }, {
    path: "/zh/flow",
    component: _ec782966,
    name: "flow___zh"
  }, {
    path: "/zh/forgot_password",
    component: _221260e4,
    name: "forgot_password___zh"
  }, {
    path: "/zh/history",
    component: _a96700aa,
    name: "history___zh"
  }, {
    path: "/zh/inquiry",
    component: _0a36fdc4,
    name: "inquiry___zh"
  }, {
    path: "/zh/list",
    component: _69860ebd,
    name: "list___zh"
  }, {
    path: "/zh/login",
    component: _993c5980,
    name: "login___zh"
  }, {
    path: "/zh/news",
    component: _05d12a9c,
    name: "news___zh"
  }, {
    path: "/zh/register",
    component: _0818aefc,
    name: "register___zh"
  }, {
    path: "/zh/sitemap",
    component: _b6cd5ae8,
    name: "sitemap___zh"
  }, {
    path: "/zh/terms",
    component: _131ac044,
    name: "terms___zh"
  }, {
    path: "/en/inquiry/completed",
    component: _094e7017,
    name: "inquiry-completed___en"
  }, {
    path: "/en/register/completed",
    component: _15f3457b,
    name: "register-completed___en"
  }, {
    path: "/ko/inquiry/completed",
    component: _094e7017,
    name: "inquiry-completed___ko"
  }, {
    path: "/ko/register/completed",
    component: _15f3457b,
    name: "register-completed___ko"
  }, {
    path: "/pt/inquiry/completed",
    component: _094e7017,
    name: "inquiry-completed___pt"
  }, {
    path: "/pt/register/completed",
    component: _15f3457b,
    name: "register-completed___pt"
  }, {
    path: "/vi/inquiry/completed",
    component: _094e7017,
    name: "inquiry-completed___vi"
  }, {
    path: "/vi/register/completed",
    component: _15f3457b,
    name: "register-completed___vi"
  }, {
    path: "/zh/inquiry/completed",
    component: _094e7017,
    name: "inquiry-completed___zh"
  }, {
    path: "/zh/register/completed",
    component: _15f3457b,
    name: "register-completed___zh"
  }, {
    path: "/en/news/:id",
    component: _46f71ccc,
    name: "news-id___en"
  }, {
    path: "/en/property/:id?",
    component: _c7b37ec8,
    name: "property-id___en"
  }, {
    path: "/ko/news/:id",
    component: _46f71ccc,
    name: "news-id___ko"
  }, {
    path: "/ko/property/:id?",
    component: _c7b37ec8,
    name: "property-id___ko"
  }, {
    path: "/pt/news/:id",
    component: _46f71ccc,
    name: "news-id___pt"
  }, {
    path: "/pt/property/:id?",
    component: _c7b37ec8,
    name: "property-id___pt"
  }, {
    path: "/vi/news/:id",
    component: _46f71ccc,
    name: "news-id___vi"
  }, {
    path: "/vi/property/:id?",
    component: _c7b37ec8,
    name: "property-id___vi"
  }, {
    path: "/zh/news/:id",
    component: _46f71ccc,
    name: "news-id___zh"
  }, {
    path: "/zh/property/:id?",
    component: _c7b37ec8,
    name: "property-id___zh"
  }, {
    path: "/en/:prefecture",
    component: _6bfe9cab,
    name: "prefecture___en"
  }, {
    path: "/ko/:prefecture",
    component: _6bfe9cab,
    name: "prefecture___ko"
  }, {
    path: "/news/:id",
    component: _46f71ccc,
    name: "news-id___ja"
  }, {
    path: "/property/:id?",
    component: _c7b37ec8,
    name: "property-id___ja"
  }, {
    path: "/pt/:prefecture",
    component: _6bfe9cab,
    name: "prefecture___pt"
  }, {
    path: "/vi/:prefecture",
    component: _6bfe9cab,
    name: "prefecture___vi"
  }, {
    path: "/zh/:prefecture",
    component: _6bfe9cab,
    name: "prefecture___zh"
  }, {
    path: "/en/:prefecture/lines",
    component: _321de458,
    name: "prefecture-lines___en"
  }, {
    path: "/en/:prefecture/list",
    component: _44a99dd5,
    name: "prefecture-list___en"
  }, {
    path: "/en/:prefecture/stations",
    component: _5a55b2f6,
    name: "prefecture-stations___en"
  }, {
    path: "/ko/:prefecture/lines",
    component: _321de458,
    name: "prefecture-lines___ko"
  }, {
    path: "/ko/:prefecture/list",
    component: _44a99dd5,
    name: "prefecture-list___ko"
  }, {
    path: "/ko/:prefecture/stations",
    component: _5a55b2f6,
    name: "prefecture-stations___ko"
  }, {
    path: "/pt/:prefecture/lines",
    component: _321de458,
    name: "prefecture-lines___pt"
  }, {
    path: "/pt/:prefecture/list",
    component: _44a99dd5,
    name: "prefecture-list___pt"
  }, {
    path: "/pt/:prefecture/stations",
    component: _5a55b2f6,
    name: "prefecture-stations___pt"
  }, {
    path: "/vi/:prefecture/lines",
    component: _321de458,
    name: "prefecture-lines___vi"
  }, {
    path: "/vi/:prefecture/list",
    component: _44a99dd5,
    name: "prefecture-list___vi"
  }, {
    path: "/vi/:prefecture/stations",
    component: _5a55b2f6,
    name: "prefecture-stations___vi"
  }, {
    path: "/zh/:prefecture/lines",
    component: _321de458,
    name: "prefecture-lines___zh"
  }, {
    path: "/zh/:prefecture/list",
    component: _44a99dd5,
    name: "prefecture-list___zh"
  }, {
    path: "/zh/:prefecture/stations",
    component: _5a55b2f6,
    name: "prefecture-stations___zh"
  }, {
    path: "/",
    component: _5fbd8934,
    name: "index___ja"
  }, {
    path: "/:prefecture",
    component: _6bfe9cab,
    name: "prefecture___ja"
  }, {
    path: "/:prefecture/lines",
    component: _321de458,
    name: "prefecture-lines___ja"
  }, {
    path: "/:prefecture/list",
    component: _44a99dd5,
    name: "prefecture-list___ja"
  }, {
    path: "/:prefecture/stations",
    component: _5a55b2f6,
    name: "prefecture-stations___ja"
  }],

  stringifyQuery: function(query) {
      const queryString = require('qs').stringify(query, {
        arrayFormat: 'comma',
        encode: false
      })

      return queryString ? `?${queryString}` : ''
    },
  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
