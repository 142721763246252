import Vue from 'vue'
import priceFormat from '@/filters/number-format'

export function stripTags(value) {
  return value.replace(/<[^>]*>?/gm, '')
}

const filters = { stripTags, priceFormat }

export default filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
