import {
  AREA_MAX,
  AREA_MIN,
  PRICE_DIVIDER,
  PRICE_RANGE_MAX,
  PRICE_RANGE_MIN
} from '@/constants/search'
import { cloneDeep, isArray, join } from 'lodash'

import stringUtils from '~/utils/stringUtils'

const stringifyArray = (value) => {
  if (!isArray(value)) return ''

  return join(value, ',')
}

export default {
  /**
   * @param prefId
   * @param query
   * @returns {object}
   */
  getSearchParamBuilder(prefId, query) {
    return this.filterParam(this.buildSearchParam(prefId, query))
  },

  /**
   * @param prefId
   * @param query
   * @returns {string}
   */
  getSearchURLBuilder(prefId, query) {
    const params = this.filterParam(this.buildSearchParam(prefId, query))
    const keys = Object.keys(params)
    let url = ''
    keys.forEach((key) => {
      const data = cloneDeep(params[key])
      if (data) {
        if (Array.isArray(data)) {
          if (data.length) {
            url += `&${key}[]=` + data.join(`&${key}[]=`)
          }
        } else {
          url += `&${key}=` + data
        }
      }
    })

    return url
  },

  filterParam(params) {
    const keys = Object.keys(params)
    const newParams = {}

    keys.forEach((key) => {
      const data = cloneDeep(params[key])
      if (data) {
        if (Array.isArray(data)) {
          if (data.length) {
            newParams[key] = data
          }
        } else {
          newParams[key] = data
        }
      }
    })

    return newParams
  },

  buildSearchParam(prefId, query) {
    const params = {
      municipalityCodes: stringUtils.paramToArray(query.municipality_code),
      masterLineIds: stringUtils.paramToArray(query.lines),
      masterStationIds: stringUtils.paramToArray(query.stations),
      recommends: stringUtils.paramToArray(query.recommend),
      housePlans: stringUtils.paramToArray(query.room),
      propertyTypes: stringUtils.paramToArray(query.property_type_id),
      buildingEquipments: stringUtils.paramToArray(query.buildingEquipment),
      bathroomToilets: stringUtils.paramToArray(query.bathroomToilet),
      kitchens: stringUtils.paramToArray(query.kitchen),
      securities: stringUtils.paramToArray(query.security),
      others: stringUtils.paramToArray(query.others),
      priceFrom:
        query.priceFrom === undefined
          ? ''
          : `${
              Number(query.priceFrom) === PRICE_RANGE_MIN
                ? ''
                : query.priceFrom * PRICE_DIVIDER
            }`,
      priceTo:
        query.priceTo === undefined
          ? ''
          : `${
              Number(query.priceTo) === PRICE_RANGE_MAX
                ? ''
                : query.priceTo * PRICE_DIVIDER
            }`,
      areaFrom:
        query.areaFrom === undefined
          ? ''
          : `${Number(query.areaFrom) === AREA_MIN ? '' : query.areaFrom}`,
      areaTo:
        query.areaTo === undefined
          ? ''
          : `${Number(query.areaTo) === AREA_MAX ? '' : query.areaTo}`,
      orderBy: query.orderBy === undefined ? '' : `${query.orderBy}`,
      perPage: query.perPage === undefined ? '' : `${query.perPage}`,
      page: query.page === undefined ? '' : `${query.page}`,
      theme: query.theme === undefined ? '' : `${query.theme}`,
      dateBuild: query.dateBuild === undefined ? '' : `${query.dateBuild}`,
      walkingTime: query.walkingTime === undefined ? '' : `${query.walkingTime}`
    }

    if (prefId) {
      params.masterPrefectureId = prefId
    }

    if (query._prefecture_id) {
      params.masterPrefectureId = query._prefecture_id
    }

    return params
  },

  parseApiParamToClientQuery(params) {
    const query = {
      municipality_code: stringifyArray(params.municipalityCodes),
      lines: stringifyArray(params.masterLineIds),
      stations: stringifyArray(params.masterStationIds),
      recommend: stringifyArray(params.recommends),
      room: stringifyArray(params.housePlans),
      property_type_id: stringifyArray(params.propertyTypes),
      buildingEquipment: stringifyArray(params.buildingEquipments),
      bathroomToilet: stringifyArray(params.bathroomToilets),
      kitchen: stringifyArray(params.kitchens),
      security: stringifyArray(params.security),
      others: stringifyArray(params.others),
      priceFrom: this.parsePriceParamToClientQuery(params.priceFrom),
      priceTo: this.parsePriceParamToClientQuery(params.priceTo),
      areaFrom: params.areaFrom,
      areaTo: params.areaTo,
      orderBy: params.orderBy,
      theme: params.theme,
      dateBuild: params.dateBuild,
      walkingTime: params.walkingTime,
      _prefecture_id: params.masterPrefectureId,
      prefId: params.masterPrefectureId
    }

    Object.keys(query).forEach((key) => {
      if (query[key] === null || query[key] === '') {
        delete query[key]
      }
    })

    return query
  },

  parsePriceParamToClientQuery(price) {
    if (price === undefined) return ''

    return price / PRICE_DIVIDER
  }
}
