var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"yl-nav",class:_vm.primaryColor},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"yl-nav-flex"},[_c('div',{staticClass:"yl-nav__left"},[_c('nuxt-link',{attrs:{"to":_vm.localePath('/')}},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":_vm.logoPath,"alt":_vm.$t('headMeta.title')}})])],1),_vm._v(" "),_c('div',{staticClass:"yl-nav__right"},[_c('div',{class:_vm.isOpenNav ? 'nav-mb-overlay' : ''},[_c('ul',{staticClass:"nav-mb",class:_vm.isOpenNav ? 'show' : 'hide'},[(_vm.$auth.loggedIn)?_c('li',{staticClass:"hide-pc"},[_c('div',{staticClass:"accordin-head",class:_vm.isOpenAccordin ? 'open-accordin' : 'close-accordin',on:{"click":_vm.openAccordin}},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"mr-2 ic-account",attrs:{"data-src":require("assets/img/ic-account@2x.png"),"alt":"account"}}),_vm._v(" "),(_vm.$auth.user)?_c('u',{staticClass:"yl-nav__item text-underline"},[_vm._v(_vm._s(_vm.$auth.user.name))]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"accordin-body",class:_vm.isOpenAccordin ? 'open-accordin' : 'close-accordin'},[_c('a',{staticClass:"yl-dropdown__item",attrs:{"target":"_blank","href":_vm.EXTERNAL_LINK.YOLO_JAPAN_CHANGE_LOGIN_INFO_URL.replace(
                          '{locale}',
                          _vm.$i18n.locale
                        )}},[_vm._v(_vm._s(_vm.$t('change_basic_information'))+"\n                      "),_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":require("assets/img/ic-arr.svg"),"alt":"new tab"}})]),_vm._v(" "),_c('a',{staticClass:"yl-dropdown__item",attrs:{"target":"_blank","href":_vm.EXTERNAL_LINK.YOLO_JAPAN_CHANGE_LOGIN_INFO_URL.replace(
                          '{locale}',
                          _vm.$i18n.locale
                        )}},[_vm._v("\n                      "+_vm._s(_vm.$t('change_login_information'))+"\n                      "),_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":require("assets/img/ic-arr.svg"),"alt":"new tab"}})]),_vm._v(" "),_c('span',{on:{"click":_vm.logout}},[_vm._v(_vm._s(_vm.$t('log_out')))])])]):_vm._e(),_vm._v(" "),_c('li',{staticClass:"hide-mb"},[(_vm.$auth.user)?_c('ELDropdown',[_c('u',{staticClass:"yl-nav__item text-underline"},[_vm._v(_vm._s(_vm.$auth.user.name))]),_vm._v(" "),_c('ELDropdownMenu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('ELDropdownItem',[_c('a',{staticClass:"yl-dropdown__item",attrs:{"target":"_blank","href":_vm.EXTERNAL_LINK.YOLO_JAPAN_CHANGE_LOGIN_INFO_URL.replace(
                              '{locale}',
                              _vm.$i18n.locale
                            )}},[_vm._v(_vm._s(_vm.$t('change_basic_information'))+"\n                          "),_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":require("assets/img/ic-arr.svg"),"alt":"new tab"}})])]),_vm._v(" "),_c('ELDropdownItem',[_c('a',{staticClass:"yl-dropdown__item",attrs:{"target":"_blank","href":_vm.EXTERNAL_LINK.YOLO_JAPAN_CHANGE_LOGIN_INFO_URL.replace(
                              '{locale}',
                              _vm.$i18n.locale
                            )}},[_vm._v("\n                          "+_vm._s(_vm.$t('change_login_information'))+"\n                          "),_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":require("assets/img/ic-arr.svg"),"alt":"new tab"}})])]),_vm._v(" "),_c('ELDropdownItem',{nativeOn:{"click":function($event){return _vm.logout($event)}}},[_vm._v(_vm._s(_vm.$t('log_out')))])],1)],1):_vm._e()],1),_vm._v(" "),(!_vm.$auth.user)?_c('li',{on:{"click":_vm.onClickNavItem}},[_c('nuxt-link',{attrs:{"to":_vm.localePath('login')}},[_vm._v("\n                    "+_vm._s(_vm.$t('login'))+"\n                  ")])],1):_vm._e(),_vm._v(" "),(_vm.isOpenNav)?[_c('li',{staticClass:"yj-sp-title"},[_c('hr'),_vm._v(" "),_c('a',{attrs:{"href":_vm.topUrl}},[_vm._v("\n                      "+_vm._s(_vm.$t('yj_nav.main'))+"\n                    ")])]),_vm._v(" "),_c('li',[_c('a',{staticClass:"mr-3",attrs:{"href":_vm.jobUrl}},[_vm._v("\n                      "+_vm._s(_vm.$t('yj_nav.job_search'))+"\n                    ")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":_vm.surveyUrl}},[_vm._v("\n                      "+_vm._s(_vm.$t('yj_nav.survey_search'))+"\n                    ")])])]:_vm._e(),_vm._v(" "),_c('button',{staticClass:"btn btn-close",on:{"click":_vm.openNav}},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":require("assets/img/ic-close-sidebar@2x.png"),"alt":"close"}})])],2)]),_vm._v(" "),_c('div',{staticClass:"open-lang-modal-btn",on:{"click":_vm.showLangModal}},[_vm._v("\n              "+_vm._s(_vm.langLabel)+"\n            ")]),_vm._v(" "),_c('button',{staticClass:"ic-menu-mb",on:{"click":_vm.closeNav}},[_c('i',{staticClass:"ic-hamburger"})])])])])])]),_vm._v(" "),(_vm.isShowLangModal)?[_c('LanguagesModal',{on:{"change":_vm.changeLocale,"close":_vm.closeLangModal}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }