import { cloneDeep, forEach } from 'lodash'

import { GRID_IMAGE } from '@/constants/search'
import { THEME_SETTING } from '@/constants/theme'

const initialState = {
  prefData: {},
  prefectures: [],
  regionPrefs: [],
  propertySelected: [],
  propertyCount: 0,
  gridView: GRID_IMAGE,
  buildings: [],
  propertyPageTotal: 0,
  metaData: {
    total: 0
  },
  addressData: {
    cities: [],
    lines: [],
    stations: []
  },
  filter: {
    label: '',
    selectedPrefecture: '',
    selectedPrefectureId: null,
    cityData: {
      areas: [],
      cities: []
    },
    lineData: {
      lines: [],
      companies: []
    },
    stationData: {
      lines: [],
      stations: []
    }
  },
  themes: []
}

export const state = () => ({ ...initialState })

export const mutations = {
  RESET_STATE: (state, payload) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, initialState)
  },
  UPDATE_PREF_DATA: (state, payload) => {
    state.prefData = payload
  },
  UPDATE_PREFECTURES: (state, payload) => {
    state.prefectures = payload
  },
  UPDATE_REGION_PREFS: (state, payload) => {
    state.regionPrefs = payload
  },
  UPDATE_PROPERTY_SELECTED: (state, payload) => {
    state.propertySelected = payload
  },
  UPDATE_GRID_VIEW: (state, payload) => {
    state.gridView = payload
  },
  UPDATE_PROPERTY_COUNT: (state, payload) => {
    state.propertyCount = payload
  },
  UPDATE_BUILDING_LIST: (state, buildingData) => {
    const buildings = buildingData.data.filter(
      (building) => building.properties && building.properties.length
    )
    state.buildings = buildings
    state.metaData = buildingData.meta
    state.propertyPageTotal = buildings.reduce((total, building) => {
      if (building.properties) {
        return total + building.properties.length
      }
      return 0
    }, 0)
  },
  UPDATE_ADDRESS_DATA: (state, payload) => {
    state.addressData = payload
  },
  UPDATE_FILTER_PREFECTURE: (state, payload) => {
    state.filter.selectedPrefecture = payload.slug
    state.filter.selectedPrefectureId = payload.id
  },
  UPDATE_FILTER_CITY_DATA: (state, payload) => {
    state.filter.cityData = payload
  },
  UPDATE_FILTER_LINE_DATA: (state, payload) => {
    state.filter.lineData = payload
  },
  UPDATE_FILTER_STATION_DATA: (state, payload) => {
    state.filter.stationData = payload
  },
  UPDATE_THEMES: (state, payload) => {
    state.themes = payload
  },
  SET_PROPERTY_FAVORITE: (state, { propertyId, isFavorite }) => {
    forEach(state.buildings, (building, currentBuildingIdx) => {
      const propertyIdx = building.properties.findIndex(
        (property) => property.id === propertyId
      )

      if (propertyIdx !== -1) {
        return (state.buildings[currentBuildingIdx].properties[
          propertyIdx
        ].is_favorite = isFavorite)
      }
    })
  }
}

export const actions = {
  async getPropertyCount(context, { prefId, query }) {
    const propertyCount = await this.$services.search
      .getPropertyCount(prefId, query)
      .then((res) => {
        return res.data.total_count
      })

    context.commit('UPDATE_PROPERTY_COUNT', propertyCount)
  },

  async fetchBuildingList(context, { prefId, query }) {
    const buildingData = await this.$services.list
      .getBuildingList(prefId, query)
      .then((res) => {
        return res
      })

    context.commit('UPDATE_BUILDING_LIST', buildingData)
  },

  async fetchAddressData(context, { prefId, query }) {
    const addressData = await this.$services.list
      .getAddressBuildingList(prefId, query)
      .then((res) => {
        return res.data
      })

    context.commit('UPDATE_ADDRESS_DATA', addressData)
  },

  async setFilterCityData(context, pref) {
    const cityData = await this.$services.pref.getCityData(pref)
    context.commit('UPDATE_FILTER_CITY_DATA', cloneDeep(cityData))
  },
  async setFilterLineData(context, prefId) {
    const lineData = await this.$services.line.getLineData(prefId)
    context.commit('UPDATE_FILTER_LINE_DATA', lineData)
  },
  async setFilterStationData(context, { prefId, query }) {
    const stationData = await this.$services.station.getStationData(
      prefId,
      query
    )
    context.commit('UPDATE_FILTER_STATION_DATA', stationData)
  },
  async getPropertyThemes(context, { prefId, query }) {
    const themeConfig = cloneDeep(THEME_SETTING)
    const themes = await Promise.all(
      themeConfig.map((theme) => {
        const criteria = {
          theme: theme.id
        }

        return this.$services.search
          .getPropertyCount(prefId, criteria)
          .then((res) => {
            return {
              ...theme,
              properties: res.data.total_count
            }
          })
      })
    )

    context.commit('UPDATE_THEMES', themes)
  }
}
