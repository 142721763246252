import { PRICE_RANGE_MIN, PRICE_RANGE_MAX } from '@/constants/search'

const initialState = {
  price: {
    from: PRICE_RANGE_MIN,
    to: PRICE_RANGE_MAX
  }
}

export const state = () => ({ ...initialState })

export const mutations = {
  UPDATE_PRICE: (state, payload) => {
    state.price.from = payload.from
    state.price.to = payload.to
  }
}
