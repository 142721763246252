const YJ_DOMAIN = 'https://www.yolo-japan.com/'
const YJ_JOB_PATH = 'recruit/job'
const YJ_SURVEY_PATH = 'recruit/survey'

const initialState = {
  topUrl: 'topUrl',
  jobUrl: 'jobUrl',
  surveyUrl: 'surveyUrl'
}

export const state = () => ({ ...initialState })

export const mutations = {
  SET_LOCALE: (state, locale) => {
    state.topUrl = `${YJ_DOMAIN}${locale}/`
    state.jobUrl = `${state.topUrl}${YJ_JOB_PATH}`
    state.surveyUrl = `${state.topUrl}${YJ_SURVEY_PATH}`
  }
}
