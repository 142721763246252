<template>
  <div class="search-tab">
    <ul class="nav-tab">
      <li
        v-for="nav in navs"
        :key="nav.id"
        class="nav-item has-cursor-pointer"
        @click="changeCategory(nav.id)"
      >
        <a
          :class="'nav-link ' + (navSelected === nav.id ? 'active' : '')"
          class="h-100"
        >
          <img class="icon" v-lazy-load :data-src="nav.icon" />
          <span>{{ nav.name }}</span>
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane active">
        <span v-show="navSelected === SEARCH_TYPE_AREA" class="tab-noti">{{
          $t('please_select_a_prefecture')
        }}</span>
        <span v-show="navSelected === SEARCH_TYPE_STATION" class="tab-noti">{{
          $t('please_select_a_train_line')
        }}</span>
        <div v-for="region in regions" :key="region.code" class="tab-item">
          <div class="item-title">
            {{ getName(region) }}
          </div>
          <div class="item-row">
            <nuxt-link
              v-for="item in region.children"
              :key="item.code"
              class="item-link"
              :to="getLink(item.code)"
            >
              <span>{{ getName(item) }}</span>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import regions from '@/static/datasource/regions.json'
import { getLocaleName } from '@/utils/language'
import { SEARCH_TYPE_AREA, SEARCH_TYPE_STATION } from '@/constants/config'

export default {
  name: 'SearchByAreaBox',
  data() {
    return {
      regions,
      SEARCH_TYPE_AREA,
      SEARCH_TYPE_STATION,
      navSelected: SEARCH_TYPE_AREA,
      linePath: '/lines',
      navs: [
        {
          id: SEARCH_TYPE_AREA,
          name: this.$t('search_by_area'),
          icon: require('../../../assets/img/ic-area@2x.png')
        },
        {
          id: SEARCH_TYPE_STATION,
          name: this.$t('search_by_train_linestation'),
          icon: require('../../../assets/img/ic-station@2x.png')
        }
      ]
    }
  },
  computed: {
    selectedLocaleCode() {
      return this.$i18n.locale
    }
  },
  created() {
    this.navSelected = this.navs
      .map((nav) => nav.id)
      .includes(this.$route.query.type)
      ? this.$route.query.type
      : SEARCH_TYPE_AREA
  },
  methods: {
    getName(item) {
      return getLocaleName(item, this.selectedLocaleCode)
    },
    getLink(code) {
      const path =
        this.navSelected === this.SEARCH_TYPE_AREA ? code : code + this.linePath
      return this.localePath(`/${path}`)
    },
    changeCategory(id) {
      this.navSelected = id
    }
  }
}
</script>
