import Prefecture from '@/domain/entities/Prefecture'
import { getLocaleName } from '@/utils/language'

/**
 * @param {Object} data
 * @param {string} locale
 */
export default function(data, locale) {
  const attributes = Object.assign({}, data)
  attributes.ja = attributes.name
  attributes.name = getLocaleName(attributes, locale)
  attributes.slug = attributes.en ? attributes.en.toLowerCase() : ''

  return new Prefecture(attributes)
}
