import { SEARCH_COOKIE_EXPIRED, SEARCH_COOKIE_NAME } from '@/constants/search'

import searchUtil from '~/utils/search-util'

export default ($axios, app) => ({
  async getAddressBuildingList(prefId, query) {
    const cities = query.municipality_code || ''
    const lines = query.lines || ''
    const stations = query.stations || ''

    return await $axios.get(
      `address/building/list?cities=${cities}&lines=${lines}&stations=${stations}`
    )
  },
  setSearchCriterias(prefId, query) {
    const isAuth = app.$auth.loggedIn
    const searchParams = searchUtil.getSearchParamBuilder(prefId, query)
    isAuth && $axios.post(`search-criterias`, searchParams)
    app.$cookies.set(SEARCH_COOKIE_NAME, JSON.stringify(searchParams), {
      path: '/',
      maxAge: SEARCH_COOKIE_EXPIRED
    })
  },
  getSearchCriterias() {
    const isAuth = app.$auth.loggedIn

    if (isAuth) {
      return $axios
        .get(`search-criterias/latest`)
        .then((res) =>
          res.data && res.data.search_criteria
            ? searchUtil.parseApiParamToClientQuery(res.data.search_criteria)
            : null
        )
    }

    return new Promise((resolve) => {
      const searchCriteria = app.$cookies.get(SEARCH_COOKIE_NAME)
      const searchCriteriaParsed = searchCriteria
        ? searchUtil.parseApiParamToClientQuery(searchCriteria)
        : null

      return resolve(searchCriteriaParsed)
    })
  },
  async getBuildingList(prefId, query) {
    try {
      let searchString = searchUtil.getSearchURLBuilder(prefId, query)
      const isAuth = app.$auth.loggedIn

      if (isAuth) {
        const userId = app.$auth.$state.user.yolo_id
        searchString += `&userId=${userId}`
      }

      this.setSearchCriterias(prefId, query)

      return await $axios.get(`buildings/search?` + searchString)
    } catch (e) {
      console.error('getBuildingList', e)
    }
  }
})
