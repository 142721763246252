import { DEFAULT_LANG_LOCALE, LANG_COOKIE_EXPIRY_DATES } from '~/constants'

const setLocaleLanguage = (locale) => {
  const expiryDates =
    +new Date() + LANG_COOKIE_EXPIRY_DATES * 24 * 60 * 60 * 1000 // one year

  const cookieLang = `lang=${locale}`
  const cookieExpires = `expires=${new Date(expiryDates).toUTCString()}`
  const path = `path=/`

  document.cookie = `${cookieLang}; ${cookieExpires}; ${path};`
}

const getCurrentLang = () => {
  const lang = document.cookie
    .split('; ')
    .find((cookie) => cookie.startsWith('lang'))

  return lang ? lang.split('=')[1] : DEFAULT_LANG_LOCALE
}

const hasLocaleCookie = () => {
  const lang = document.cookie
    .split('; ')
    .find((cookie) => cookie.startsWith('lang'))

  return !!lang
}

const getLocaleName = (item, locale) => {
  if (item === undefined) {
    return ''
  }

  if (locale === DEFAULT_LANG_LOCALE) {
    return item.name
  } else {
    if (locale in item) {
      return item[locale]
    }

    if (item.en !== undefined) {
      return item.en
    }

    return item.name
  }
}

export { setLocaleLanguage, getCurrentLang, hasLocaleCookie, getLocaleName }
