<template>
  <div class="list-search search-theme">
    <div class="row">
      <div class="col-lg-12">
        <div class="section-title">
          <div class="headTitle fs-26 mb-24">
            {{ $t('refine_search') }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="list-link">
          <div>
            <nuxt-link
              v-for="theme in themes"
              :key="theme.id"
              :to="localePath(`/list?theme=${theme.id}`)"
            >
              {{ $t(theme.i18n_key) }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  THEME_DESIGNERS,
  THEME_RENOVATION,
  THEME_SECURITY,
  THEME_STUDENT,
  THEME_NEWLYWED_COUPLE,
  THEME_FAMILY_PROPERTY,
  THEME_PET_ALLOWED,
  THEME_WITHIN_10_MINUTES_WALK_FROM_STATION,
  THEME_HAS_MOTORCYCLE_PARKING,
  THEME_HAS_BICYCLE_PARKING,
  THEME_HAS_CAR_PARKING
} from '@/constants/theme'

export default {
  name: 'RecomendCriteriaTheme',
  data() {
    return {
      themes: {
        THEME_DESIGNERS,
        THEME_RENOVATION,
        THEME_SECURITY,
        THEME_STUDENT,
        THEME_NEWLYWED_COUPLE,
        THEME_FAMILY_PROPERTY,
        THEME_PET_ALLOWED,
        THEME_WITHIN_10_MINUTES_WALK_FROM_STATION,
        THEME_HAS_MOTORCYCLE_PARKING,
        THEME_HAS_BICYCLE_PARKING,
        THEME_HAS_CAR_PARKING
      }
    }
  }
}
</script>
