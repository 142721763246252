export const FEATURED_PROPERTY_CONFIG = [
  {
    title: 'newly_built_properties',
    description: 'start_your_new_life_in_a_beautiful_room',
    image: 'fp_newly_built_properties.jpg',
    target_path: '/list?dateBuild=5'
  },
  {
    title: 'designer_properties',
    description: 'express_yourself_in_a_stylish_home',
    image: 'fp_designer_properties.jpg',
    target_path: '/list?theme=10'
  },
  {
    title: 'super_secure_rooms_for_women',
    description: 'extra_security_rooms_on_the_2nd_floor_or_higher',
    image: 'fp_super_secure_rooms_for_women.jpg',
    target_path: '/list?security=20501,23801,26201'
  },
  {
    title: 'renovated_properties',
    description: 'great_rooms_at_an_affordable_price_recommended_for_students',
    image: 'fp_renovated_properties.jpg',
    target_path: '/list?theme=11'
  },
  {
    title: 'affordable_properties_at_50000_yen_per_month_or_less_no_deposit',
    description:
      'affordable_properties_with_maintenance_fees_included_recommended_for_students',
    image:
      'fp_affordable_properties_at_50000_yen_per_month_or_less_no_deposit.jpg',
    target_path: '/list?priceTo=50&recommend=2,1,3'
  },
  {
    title: 'properties_close_to_stations',
    description: 'get_to_workschool_easily',
    image: 'fp_properties_close_to_stations.jpg',
    target_path: '/list?walkingTime=10'
  },
  {
    title: 'relax_in_a_2ldk_property_all_to_yourself',
    description:
      'have_fun_choosing_the_interior_and_have_friends_over_in_these_larger_rooms',
    image: 'fp_relax_in_a_2ldk_property_all_to_yourself.jpg',
    target_path: '/list?room=5,6,7'
  },
  {
    title: 'properties_within_5_minutes_of_the_station_with_auto_lock_feature',
    description:
      'feel_safe_walking_home_recommended_for_those_who_get_home_late',
    image:
      'fp_properties_within_5_minutes_of_the_station_with_auto_lock_feature.jpg',
    target_path: '/list?walkingTime=5&security=20501'
  }
]
