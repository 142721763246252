const DEFAULT_ID = 1

const initialState = {
  inquiryFormData: {
    phone_number: '',
    contact_item_id: DEFAULT_ID,
    contact_move_in_date_id: DEFAULT_ID,
    contact_japanese_level_id: DEFAULT_ID,
    contact_language_id: '',
    identification_card_front: null,
    identification_card_back: null,
    residence_card_front: null,
    residence_card_back: null,
    identification_id: DEFAULT_ID,
    request_estimate: 'yes',
    plan: '',
    number_of_people: '',
    layout: '',
    schedule_date: '',
    desired_date_1: '',
    desired_date_2: '',
    desired_date_3: '',
    family_name: '',
    given_name: '',
    middle_name: '',
    family_name_ruby: '',
    given_name_ruby: '',
    middle_name_ruby: '',
    zip_code: '',
    master_prefecture_id: '',
    municipality_code: '',
    city: '',
    address: ''
  },
  inquiryImages: {},
  isAgree: false,
  isSending: false,
  inquiringProperties: [],
  currentStep: {}
}

export const state = () => JSON.parse(JSON.stringify(initialState))

export const mutations = {
  RESET_STATE: (state, payload) => {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, initialState)
  },
  SET_INQUIRY_FORM_DATA: (state, payload) => {
    state.inquiryFormData = payload
  },
  SET_INQUIRY_IMAGES: (state, payload) => {
    console.log(payload)
    state.inquiryImages = payload
  },
  SET_IS_AGREE: (state, payload) => {
    state.isAgree = payload
  },
  SET_INQUIRING_PROPERTIES: (state, payload) => {
    state.inquiringProperties = payload
  },
  SET_IS_SENDING: (state, payload) => {
    state.isSending = payload
  },
  SET_CURRENT_STEP: (state, payload) => {
    state.currentStep = payload
  }
}
