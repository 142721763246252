<template>
  <div class="wrapper">
    <AppNav />
    <nuxt />
    <AppFooter />
  </div>
</template>

<script>
import AppNav from '@/components/layout/AppNav'
import AppFooter from '@/components/layout/AppFooter'

export default {
  components: { AppNav, AppFooter }
}
</script>
