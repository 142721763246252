const importJson = (prefecture) =>
  import('@/datasource/area/' + prefecture + '.json')

export default ($axios, app) => ({
  async getCityData(prefecture, includeCounter = true) {
    if (prefecture === '') {
      return {
        prefId: 0,
        cities: [],
        areas: [],
        propertyTotal: []
      }
    }

    const cities = await importJson(prefecture).then(
      ({ default: prefectures }) => {
        return prefectures
      }
    )
    const prefId = cities[0].id
    const cityCodes = cities.map((v) => v.code).join(',')
    const propertyTotal = includeCounter
      ? await this.getCityPropertyTotal(prefId, cityCodes).then((res) => {
          return res.data
        })
      : []
    const areas = this.setAreaGroup(cities, propertyTotal)

    return {
      prefId,
      cities,
      areas,
      propertyTotal
    }
  },
  async getCityPropertyTotal(prefId, cityCodes) {
    return await $axios.get(
      `/properties/${prefId}/city/total?cities=${cityCodes}`
    )
  },
  setAreaGroup(cities, propertyTotal) {
    const areas = []
    for (const key in cities) {
      const city = cities[key]
      city.id = Number(city.id)
      city.name = city.city
      city.en = city.city_en

      const cityIndex = propertyTotal.findIndex((v) => v.code === city.code)
      const areaIndex = areas.findIndex((v) => v.name === city.area)

      city.total = 0
      if (cityIndex > -1) {
        city.total = propertyTotal[cityIndex].total
      }

      const areaKey = city.id + '-' + city.code

      if (areaIndex > -1) {
        city.parent = areas[areaIndex].key
        areas[areaIndex].cities.push(city)
        areas[areaIndex].total += city.total
        city.total && areas[areaIndex].codes.push(city.code)
      } else {
        const area = {
          key: areaKey,
          name: city.area,
          en: city.area_en,
          total: city.total,
          cities: [],
          codes: city.total ? [city.code] : []
        }
        city.parent = areaKey
        area.cities.push(city)
        areas.push(area)
      }
    }

    return areas
  }
})
