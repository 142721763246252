<template>
  <nav class="yl-nav" :class="primaryColor">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="yl-nav-flex">
            <div class="yl-nav__left">
              <nuxt-link :to="localePath('/')">
                <img v-lazy-load :data-src="logoPath" :alt="$t('headMeta.title')" />
              </nuxt-link>
            </div>
            <div class="yl-nav__right">
              <div :class="isOpenNav ? 'nav-mb-overlay' : ''">
                <ul :class="isOpenNav ? 'show' : 'hide'" class="nav-mb">
                  <!-- SP nav -->
                  <template v-if="isOpenNav">
                    <li v-if="$auth.loggedIn" class="hide-pc">
                      <div
                        class="accordin-head"
                        :class="
                          isOpenAccordin ? 'open-accordin' : 'close-accordin'
                        "
                        @click="openAccordin"
                      >
                        <img
                          class="mr-2 ic-account"
                          v-lazy-load data-src="~assets/img/ic-account@2x.png"
                          :alt="$t('login')"
                        />
                        <u
                          v-if="$auth.user"
                          class="yl-nav__item text-underline"
                          >{{ $auth.user.name }}</u
                        >
                      </div>
                      <div
                        class="accordin-body"
                        :class="
                          isOpenAccordin ? 'open-accordin' : 'close-accordin'
                        "
                      >
                        <a
                          target="_blank"
                          class="yl-dropdown__item"
                          :href="
                            EXTERNAL_LINK.YOLO_JAPAN_CHANGE_LOGIN_INFO_URL.replace(
                              '{locale}',
                              $i18n.locale
                            )
                          "
                          >{{ $t('change_basic_information') }}
                          <img v-lazy-load data-src="~assets/img/ic-arr.svg" alt="new tab" />
                        </a>
                        <a
                          target="_blank"
                          class="yl-dropdown__item"
                          :href="
                            EXTERNAL_LINK.YOLO_JAPAN_CHANGE_LOGIN_INFO_URL.replace(
                              '{locale}',
                              $i18n.locale
                            )
                          "
                        >
                          {{ $t('change_login_information') }}
                          <img v-lazy-load data-src="~assets/img/ic-arr.svg" alt="new tab" />
                        </a>
                        <span @click="logout">{{ $t('log_out') }}</span>
                      </div>
                    </li>

                    <li class="hide-pc" @click="onClickNavItem">
                      <nuxt-link :to="localePath('all')">
                        {{ $t('search') }}
                      </nuxt-link>
                    </li>

                    <li @click="onClickNavItem">
                      <nuxt-link :to="localePath('history')">
                        {{ $t('browsing_history') }}
                      </nuxt-link>
                    </li>

                    <li @click="onClickNavItem">
                      <nuxt-link :to="localePath('favorite')">
                        {{ $t('favorites') }}
                      </nuxt-link>
                      <span v-if="totalFavorites" class="favorite-count">{{
                        totalFavorites
                      }}</span>
                    </li>

                    <li class="hide-pc" @click="onClickNavItem">
                      <nuxt-link :to="localePath('flow')">
                        {{ $t('the_process_of_moving_in') }}
                      </nuxt-link>
                    </li>

                    <li @click="onClickNavItem">
                      <nuxt-link :to="localePath('faq')">
                        FAQ
                      </nuxt-link>
                    </li>

                    <li
                      v-if="!$auth.loggedIn"
                      class="hide-pc"
                      @click="onClickNavItem"
                    >
                      <nuxt-link :to="localePath('register')">
                        {{ $t('member_registration') }}
                      </nuxt-link>
                    </li>
                  </template>

                  <!-- PC nav -->
                  <template v-else>
                    <li @click="onClickNavItem">
                      <nuxt-link :to="localePath('faq')">
                        FAQ
                      </nuxt-link>
                    </li>

                    <li @click="onClickNavItem">
                      <nuxt-link :to="localePath('history')">
                        {{ $t('browsing_history') }}
                      </nuxt-link>
                    </li>

                    <li @click="onClickNavItem">
                      <nuxt-link :to="localePath('favorite')">
                        {{ $t('favorites') }}
                      </nuxt-link>
                      <span v-if="totalFavorites" class="favorite-count">{{
                        totalFavorites
                      }}</span>
                    </li>
                    <li class="hide-mb">
                      <ELDropdown v-if="$auth.user">
                        <u class="yl-nav__item text-underline">{{
                          $auth.user.name
                        }}</u>
                        <ELDropdownMenu slot="dropdown">
                          <ELDropdownItem>
                            <a
                              target="_blank"
                              class="yl-dropdown__item"
                              :href="
                                EXTERNAL_LINK.YOLO_JAPAN_CHANGE_LOGIN_INFO_URL.replace(
                                  '{locale}',
                                  $i18n.locale
                                )
                              "
                              >{{ $t('change_basic_information') }}
                              <img v-lazy-load data-src="~assets/img/ic-arr.svg" alt="new tab" />
                            </a>
                          </ELDropdownItem>
                          <ELDropdownItem>
                            <a
                              target="_blank"
                              class="yl-dropdown__item"
                              :href="
                                EXTERNAL_LINK.YOLO_JAPAN_CHANGE_LOGIN_INFO_URL.replace(
                                  '{locale}',
                                  $i18n.locale
                                )
                              "
                            >
                              {{ $t('change_login_information') }}
                              <img v-lazy-load data-src="~assets/img/ic-arr.svg" alt="new tab" />
                            </a>
                          </ELDropdownItem>
                          <ELDropdownItem @click.native="logout">{{
                            $t('log_out')
                          }}</ELDropdownItem>
                        </ELDropdownMenu>
                      </ELDropdown>
                    </li>
                  </template>

                  <li v-if="!$auth.user" @click="onClickNavItem">
                    <nuxt-link :to="localePath('login')">
                      {{ $t('login') }}
                    </nuxt-link>
                  </li>
                  <template v-if="isOpenNav">
                    <li class="yj-sp-title">
                      <hr />
                      <a :href="topUrl">
                        {{ $t('yj_nav.main') }}
                      </a>
                    </li>
                    <li>
                      <a :href="jobUrl" class="mr-3">
                        {{ $t('yj_nav.job_search') }}
                      </a>
                    </li>
                    <li>
                      <a :href="surveyUrl">
                        {{ $t('yj_nav.survey_search') }}
                      </a>
                    </li>
                  </template>
                  <button class="btn btn-close" @click="openNav">
                    <img
                      v-lazy-load data-src="~assets/img/ic-close-sidebar@2x.png"
                      alt="close"
                    />
                  </button>
                </ul>
              </div>
              <div class="open-lang-modal-btn" @click="showLangModal">
                {{ langLabel }}
              </div>
              <button class="ic-menu-mb" @click="closeNav">
                <i class="ic-hamburger"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="isShowLangModal">
      <LanguagesModal
        @change="changeLocale"
        @close="closeLangModal"
      ></LanguagesModal>
    </template>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import { has } from 'lodash'
import {
  setLocaleLanguage,
  getCurrentLang,
  hasLocaleCookie
} from '~/utils/language'
import { GOOGLE_TAG_MANAGER } from '@/constants/config'
import LanguagesModal from '~/components/common/LanguagesModal'
import routeUtil from '@/utils/route-util'
import * as EXTERNAL_LINK from '@/constants/externalLink'

export default {
  components: { LanguagesModal },
  data() {
    return {
      EXTERNAL_LINK,
      isShowLangModal: false,
      locale: null,
      currentLocale: null,
      isOpenNav: false,
      isOpenAccordin: false
    }
  },

  computed: {
    ...mapState('favorite', ['totalFavorites']),
    ...mapState('yj', ['topUrl', 'jobUrl', 'surveyUrl']),

    currentRouteName() {
      return routeUtil.retrieveRealRouteName(this.$route.name)
    },
    logoPath() {
      const isHomePage = this.currentRouteName === 'index'

      return isHomePage
        ? require('../../assets/img/logo-white.svg')
        : require('../../assets/img/logo.svg')
    },

    primaryColor() {
      const isHomePage = this.currentRouteName === 'index'

      return isHomePage ? 'c-white' : 'c-black'
    },

    langLabel() {
      const currentLocale = this.$i18n.locales.find(
        (locale) => locale.code === this.$i18n.locale
      )

      return currentLocale ? currentLocale.name : ''
    },
    loggedIn() {
      return this.$store.state.auth.loggedIn
    }
  },

  watch: {
    loggedIn: {
      handler(loggedIn) {
        return loggedIn
          ? this.injectUserToGoogleTagManager()
          : this.rejectUserFromGoogleTagManager()
      },
      immediate: true
    }
  },

  created() {
    if (this.$auth.user) {
      this.$store.commit('favorite/UPDATE_TOTAL_FAVORITES', {
        total: this.$auth.user.total_favorites
      })
    }
  },
  mounted() {
    // eslint-disable-next-line
    if (process.client) {
      this.isShowLangModal = !hasLocaleCookie()
      this.currentLocale = getCurrentLang()
    }
  },
  methods: {
    injectUserToGoogleTagManager() {
      this.$gtm.push({
        [GOOGLE_TAG_MANAGER.USER_ID_KEY]: this.loggedIn
          ? this.$store.state.auth.user.yolo_id
          : null
      })
    },
    rejectUserFromGoogleTagManager() {
      if (process.client && window && window.dataLayer) {
        window.dataLayer = window.dataLayer.filter(
          (item) => !has(item, GOOGLE_TAG_MANAGER.USER_ID_KEY)
        )
      }
    },

    showLangModal() {
      this.isShowLangModal = true
    },
    onClickNavItem() {
      this.isOpenNav = false
    },
    async logout() {
      await this.$auth.logout()
      this.$store.commit('favorite/UPDATE_TOTAL_FAVORITES', {
        total: 0
      })

      await this.$services.auth.deleteJwt()

      // this.$store.commit('inquiry/RESET_STATE')

      this.isOpenNav = false
    },

    changeLocale(locale) {
      this.locale = locale

      this.closeLangModal()
      setLocaleLanguage(locale)
      this.$router.replace(this.switchLocalePath(locale))
    },

    closeLangModal() {
      this.isShowLangModal = false
    },

    openNav() {
      this.isOpenNav = false
    },

    closeNav() {
      this.isOpenNav = true
    },

    openAccordin() {
      this.isOpenAccordin = !this.isOpenAccordin
    }
  }
}
</script>
