<template>
  <div class="list-search search-type">
    <div class="row">
      <div class="col-lg-12">
        <div class="section-title">
          <div class="headTitle fs-26 mb-24">
            {{ $t('search_by_unit_type') }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="list-link">
          <nuxt-link
            v-for="link in linkConfig"
            :key="link.property_type_id"
            :to="
              localePath({
                path: '/list',
                query: {
                  property_type_id: link.property_type_id
                }
              })
            "
            >{{ $t(link.i18n_key) }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PROPERTY_TYPES } from '@/constants/config'
export default {
  name: 'RecomendCriteriaPropertyType',
  data() {
    return {
      linkConfig: [
        {
          property_type_id: PROPERTY_TYPES.APARTMENT,
          i18n_key: 'rental_room'
        },
        {
          property_type_id: PROPERTY_TYPES.LUXURY_APARTMENT,
          i18n_key: 'rental_apartment'
        },
        {
          property_type_id: PROPERTY_TYPES.DETACHED_HOUSE,
          i18n_key: 'rental_house'
        }
      ]
    }
  }
}
</script>
