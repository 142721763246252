<template>
  <section class="section section-feature section-bg HomeSpecialFeature">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">
            <div class="headTitle">{{ $t('special_feature') }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 no-padding">
          <div class="section-card">
            <swiper
              ref="swiperRef"
              class="section-card-inner mb-scroll"
              :options="swiperOptionThumbs"
            >
              <swiper-slide
                v-for="(item, index) in FEATURED_PROPERTY_CONFIG"
                :key="index"
                class="item has-cursor-pointer h-auto"
              >
                <nuxt-link
                  :to="localePath(item.target_path)"
                  class="section-card-inner__link"
                >
                  <div class="item-top">
                    <img
                      v-lazy-load :data-src="require(`../../assets/img/${item.image}`)"
                      :alt="$t(item.title) + '|' + $t('headMeta.title')"
                    />
                  </div>
                  <div class="item-body">
                    <div class="item-title font-weight-bold">
                      {{ $t(item.title) }}
                    </div>
                    <div class="item-info">
                      {{ $t(item.description) }}
                    </div>
                  </div>
                </nuxt-link>
              </swiper-slide>
            </swiper>
            <span
              class="icon-control prev has-cursor-pointer"
              @click="slidePrev"
            ></span>
            <span
              class="icon-control next has-cursor-pointer"
              @click="slideNext"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { SWIPE_TRANSITION_DURATION } from '@/constants/config'
import { FEATURED_PROPERTY_CONFIG } from '@/constants/featured-property'

export default {
  name: 'HomeSpecialFeature',
  data() {
    return {
      FEATURED_PROPERTY_CONFIG,
      swiperOptionThumbs: {
        slidesPerView: 'auto',
        touchRatio: 1,
        loop: true
      }
    }
  },
  methods: {
    slideNext() {
      this.$refs.swiperRef.$swiper.slideNext(SWIPE_TRANSITION_DURATION)
    },
    slidePrev() {
      this.$refs.swiperRef.$swiper.slidePrev(SWIPE_TRANSITION_DURATION)
    }
  }
}
</script>

<style lang="scss" scoped>
a.section-card-inner__link:hover {
  color: unset !important;
  text-decoration: unset !important;
}
.item {
  .item-title {
    color: rgb(34, 34, 34) !important;
    text-decoration: unset !important;
  }
}
.HomeSpecialFeature {
  .headTitle {
    font-size: 26px;
  }
  .section-card {
    .section-card-inner {
      display: flex;
      justify-content: center;
      width: calc(100% - 80px);
      padding: 20px 0;

      @media screen and (max-width: 768px) {
        width: 100%;
        padding-left: 30px;
      }
    }

    .item {
      width: 241.5px;
    }
  }
}
</style>
