import { isArray } from 'lodash'

export default ($axios) => ({
  /**
   * @returns {Promise}
   */
  validateContactForm(payload) {
    return $axios.post('/contacts/validate', payload)
  },

  /**
   * @returns {Promise}
   */
  saveContact(payload) {
    return $axios.post('/contacts', payload)
  },

  /**
   * @returns {Promise}
   */
  getInquiringProperties(ids) {
    const idsString = isArray(ids) && ids.length ? ids.join(',') : ''

    return $axios.$get(`/contacts/properties?property_ids=${idsString}`)
  },

  /**
   * @returns {Promise}
   */
  getLatestContactHistory() {
    return $axios.$get('/contacts/history/latest')
  }
})
