export const YOLO_JAPAN_FACEBOOK_URL = 'https://www.facebook.com/yolojapan/'
export const YOLO_JAPAN_TWITTER_URL = 'https://twitter.com/YolobaseC'
export const YOLO_JAPAN_INSTAGRAM_URL =
  'https://www.instagram.com/yolojapan.official/'
export const YOLO_JAPAN_LINE_URL = 'https://page.line.me/yolojapan'
export const YOLO_JAPAN_TIKTOK_URL = 'https://www.tiktok.com/@yolo_japan'
export const YOLO_JAPAN_YOUTUBE_URL =
  'https://www.youtube.com/channel/UCuhX64viJ-gLZ8TEFv4M39Q'
export const YOLO_JAPAN_URL = 'https://www.yolo-japan.com'
export const YOLO_JAPAN_PRIVACY_POLICY_URL =
  'https://www.yolo-japan.co.jp/privacy_policy'
export const YOLO_BASE_URL = 'https://yolo-base.com'
export const YOLO_JAPAN_PERSONAL_INFO_URL =
  'https://www.yolo-japan.com/{locale}/recruit/mypage/profile/input/personalInfo'
export const YOLO_JAPAN_CHANGE_LOGIN_INFO_URL =
  'https://www.yolo-japan.com/{locale}/recruit/mypage/account/input'
export const YOLO_JAPAN_ABOUT_URL = 'https://www.yolo-japan.co.jp/company'
