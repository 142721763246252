import UrlHelper from '@/utils/url'

export default ($axios) => ({
  reverseGeocoding(query) {
    const queryString = UrlHelper.buildQueryString({ ...baseQuery, ...query })
    const url = `/geocode?${queryString}`

    return $axios.$get(url)
  }
})

const baseQuery = {
  language: 'ja',
  region: 'JP'
}
