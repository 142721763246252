import { FB_GRAPH_API_URL } from '@/constants/config'
// eslint-disable-next-line import/order
import axios from 'axios'

export default () => ({
  getUser(accessToken, fields) {
    const url = `${FB_GRAPH_API_URL}/me?fields=${fields.join(
      ','
    )}&access_token=${accessToken}`

    return axios.get(url)
  }
})
