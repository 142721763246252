export default {
  removeAccents(str) {
    if (!str) return str

    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036F]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
  },

  /**
   * Convert string to array by ','F
   * @param str
   * @returns {Array}
   */
  paramToArray(str) {
    let args = []
    if (str) {
      if (str.includes(',')) {
        args = str.split(',')
        args = [...new Set(args)]
        args = args.filter((v) => v !== null && v !== undefined && v.length)
      } else {
        return [str]
      }
    }

    return args
  }
}
