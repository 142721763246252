import Vue from 'vue'
import 'element-ui/lib/theme-chalk/pagination.css'

// Lazy Loading Element UI Components
export default () => {
  Vue.component('ELDropdown', () =>
    import(
      /* webpackChunkName: 'element-ui-dropdown' */
      'element-ui/lib/dropdown'
    )
  )
  Vue.component('ELDropdownMenu', () =>
    import(
      /* webpackChunkName: 'element-ui-dropdown-men' */
      'element-ui/lib/dropdown-menu'
    )
  )
  Vue.component('ELDropdownItem', () =>
    import(
      /* webpackChunkName: 'element-ui-dropdown-item' */
      'element-ui/lib/dropdown-item'
    )
  )

  // Vue.component('el-select', () =>
  //   import(
  //     /* webpackChunkName: 'element-ui-select' */
  //     'element-ui/lib/select'
  //   )
  // )

  Vue.component('el-option', () =>
    import(
      /* webpackChunkName: 'element-ui-select' */
      'element-ui/lib/option'
    )
  )

  Vue.component('el-dropdown', () =>
    import(
      /* webpackChunkName: 'element-ui-dropdown' */
      'element-ui/lib/dropdown'
    )
  )

  Vue.component('el-dropdown-menu', () =>
    import(
      /* webpackChunkName: 'element-ui-dropdown-menu' */
      'element-ui/lib/dropdown-menu'
    )
  )

  Vue.component('el-dropdown-item', () =>
    import(
      /* webpackChunkName: 'element-ui-dropdown-item' */
      'element-ui/lib/dropdown-item'
    )
  )

  Vue.component('el-pagination', () =>
    import(
      /* webpackChunkName: 'element-ui-pagination' */
      'element-ui/lib/pagination'
    )
  )
}
