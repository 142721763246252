/**
 * Customize axios by registering interceptors and changing global config
 */
export default function({ $axios, app, redirect, route }) {
  $axios.onRequest((config) => {
    config.headers['X-Localization'] = app.i18n.locale
  })

  $axios.onResponse((response) => {
    if (response.data.success) {
      delete response.data.success
      return response.data
    }
    return new Promise(function(resolve, reject) {
      reject(new Error('something bad happened'))
    })
  })

  $axios.onError((errors) => {
    const headerStatus =
      errors.response && errors.response.status ? errors.response.status : 503
    if (
      errors.response &&
      errors.response.data &&
      typeof errors.response.data === 'object'
    ) {
      if (headerStatus === 401) {
        const requestURL = errors.config.url
        const loginPaths = ['/auth/login', '/auth/login/facebook']
        if (!loginPaths.includes(requestURL)) {
          if (requestURL !== '/auth/user') {
            return app.$auth
              .logout()
              .then(() => redirect(app.localePath('/login')))
          }

          app.$auth.logout()
          app.$services.auth.deleteJwt()
        }
      }
      if (headerStatus === 422) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ validationErrors: errors.response.data.errors })
      }

      if (headerStatus === 500) {
        if (process.client) {
          alert('Something was wrong!')
        }

        console.log({
          statusCode: headerStatus,
          message: `API error: ${errors.response.data.message}`
        })
      }

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        statusCode: headerStatus,
        message: errors.response.data.message
      })
    }
  })
}
