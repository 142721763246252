import routeUtil from '~/utils/search-util'

const PREFIX = '/properties'

export default ($axios) => ({
  /**
   * Get property details
   *
   * @param {int} id Property id
   * @return {Promise}
   */
  getDetail(id) {
    return $axios.$get(`/properties/${id}`).then((res) => {
      const property = res.property
      const sameBuildingProperties = res.otherProperties
      const building = res.property.building
      const nearbyStationsGroupByLines = res.otherLineStations
      const recommendedProperties = res.otherRecommendations
      return {
        property,
        building,
        sameBuildingProperties,
        nearbyStationsGroupByLines,
        recommendedProperties
      }
    })
  },

  getPropertyMedia(id) {
    return $axios.$get(`/properties/${id}/media`)
  },

  getRecommendProperties() {
    return $axios.$get('/properties/recommend')
  },
  checkFavoriteByUser(propertyId) {
    return $axios.get(
      `/properties/check-favorite-by-user?property_id=${propertyId}`
    )
  },
  addFavorite(propertyId) {
    return $axios.post(`/properties/favorite?property_id=${propertyId}`)
  },
  getRentPrices(prefId = '', query) {
    const cities = query.cities || ''
    const lines = query.lines || ''
    const stations = query.stations || ''

    return $axios.get(
      `${PREFIX}/rent/prices?prefId=${prefId}&cities=${cities}&lines=${lines}&stations=${stations}`
    )
  },
  getSimilarProperties(id, ignoreIDs = []) {
    return $axios.$get(
      `${PREFIX}/${id}/similar?ignore_ids=${ignoreIDs.join(',')}`
    )
  },
  getPopularProperties(id) {
    return $axios.$get(`${PREFIX}/${id}/popular`)
  },
  getTotalCountPublishedProperties() {
    return $axios.$get(`${PREFIX}/total-count`).then((res) => res.total_count)
  },
  getRecentlyProperties(searchCriteria) {
    const queryString = routeUtil.getSearchURLBuilder(null, searchCriteria)

    return $axios.$get(`${PREFIX}/search?${queryString}`)
  },
  getCountRecentlyProperties(searchCriteria) {
    const queryString = routeUtil.getSearchURLBuilder(null, searchCriteria)

    return $axios
      .$get(`${PREFIX}/search/count/last-7-days?${queryString}`)
      .then((res) => res.total_count)
  },
  getUniqueryBuilding(searchCriteria) {
    const queryString = routeUtil.getSearchURLBuilder(null, searchCriteria)
    return $axios.$get(`${PREFIX}/search-unique-by-building?${queryString}`)
  }
})
