import BaseEntity from './BaseEntity'
import { find } from 'lodash'

export default class Prefecture extends BaseEntity {
  /**
   * Retrieves all cities  belong to curent pref
   *
   * @return Array
   */
  getCities() {
    try {
      return require(`~/static/datasource/area/${this.slug}.json`)
    } catch (error) {
      return []
    }
  }

  /**
   * @param {Object} criteria search criteria
   */
  findCityByCriteria(criteria) {
    const allCities = this.getCities()

    return find(allCities, criteria)
  }

  findCityByName(name) {
    return this.findCityByCriteria({
      city: name
    })
  }
}
