import { PREFECTURE_SEARCHABLE_IDS } from '@/constants/config'
import prefectureFactory from '@/domain/factories/prefectureFactory'

export default ($axios) => ({
  /**
   * @returns {Promise}
   */
  async getPrefectures(locale) {
    const prefectures = await require('@/static/datasource/prefectures.json')

    return PREFECTURE_SEARCHABLE_IDS.map((id) =>
      prefectures.find((pref) => pref.id === id)
    )
      .filter((pref) => pref !== undefined)
      .map((prefecture) => prefectureFactory(prefecture, locale))
  },

  /**
   * @returns {Promise}
   */
  async getAllPrefectures(locale) {
    const prefectures = await require('@/static/datasource/prefectures.json').map(
      (prefecture) => prefectureFactory(prefecture, locale)
    )

    return prefectures
  },

  /**
   *
   * @param {*} slug
   * @return {boolean}
   */
  async checkExistPrefectureBySlug(slug) {
    const allPrefectures = await this.getAllPrefectures()

    return (
      allPrefectures.find((prefecture) => prefecture.slug === slug) !==
      undefined
    )
  },

  findPrefecture(id, locale) {
    return new Promise((resolve, reject) => {
      const pref = this.getPrefectures(locale).then((res) =>
        res.find((pref) => pref.id === id)
      )

      if (pref) {
        resolve(pref)
      } else {
        reject(new Error('Prefecture not found'))
      }
    })
  },

  getNationalities() {
    return $axios.get('/nationalities')
  },

  getGenders() {
    return $axios.get('/genders')
  },

  getIdentifications() {
    return $axios.$get('/identifications')
  },
  getContactItems() {
    return $axios.$get('/contacts/items')
  },
  getContactMoveInDate() {
    return $axios.$get('/contacts/move-in-dates')
  },
  getContactLanguages() {
    return $axios.$get('/contacts/languages')
  },
  getContactJapaneseLevels() {
    return $axios.$get('/contacts/japanese-levels')
  },
  getContactJobs() {
    return $axios.$get('/contacts/jobs')
  },
  getMovingAsks() {
    return $axios.$get('/moving/asks')
  },
  getMovingPlans() {
    return $axios.$get('/moving/plans')
  },
  getMovingLayouts() {
    return $axios.$get('/moving/layouts')
  }
})
