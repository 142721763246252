import FACILITIES from '@/constants/facilities'

export const PRICE_RANGE_MIN = 0
export const PRICE_RANGE_DEFAULT = 85
export const PRICE_RANGE_MAX = 210
export const PRICE_DIVIDER = 1000

const STEP_START = 30
const STEP_MEDIUM = 5
const STEP_END = 10
const STEP_DIVIDER = 100
export const PRICE_RANGE_STEP_START = [0, STEP_START]
export const PRICE_RANGE_STEP_MEDIUM = [
  STEP_START + STEP_MEDIUM,
  STEP_DIVIDER,
  STEP_MEDIUM
]
export const PRICE_RANGE_STEP_END = [
  STEP_DIVIDER,
  PRICE_RANGE_MAX + STEP_END,
  STEP_END
]

export const AREA_MIN = 0
export const AREA_FROM = 10
export const AREA_TO = 100
export const AREA_STEP = 5
export const AREA_MAX = 1000

export const RECOMMEND_NO_SECURITY_DEPOSIT = 1
export const RECOMMEND_NO_KEY_MONEY = 2
export const RECOMMEND_MANAGEMENT_FEE = 3

export const HOUSE_PLAN_ONE_ROOM = 1
export const HOUSE_PLAN_1K = 2
export const HOUSE_PLAN_1DK = 3
export const HOUSE_PLAN_1LDK = 4
export const HOUSE_PLAN_2K = 5
export const HOUSE_PLAN_2DK = 6
export const HOUSE_PLAN_2LDK = 7
export const HOUSE_PLAN_3K = 8
export const HOUSE_PLAN_3DK = 9
export const HOUSE_PLAN_3LDK = 10
export const HOUSE_PLAN_4K = 11
export const HOUSE_PLAN_4DK = 12
export const HOUSE_PLAN_4LDK = 13

export const HOUSE_PLANS = {
  HOUSE_PLAN_ONE_ROOM,
  HOUSE_PLAN_1K,
  HOUSE_PLAN_1DK,
  HOUSE_PLAN_1LDK,
  HOUSE_PLAN_2K,
  HOUSE_PLAN_2DK,
  HOUSE_PLAN_2LDK,
  HOUSE_PLAN_3K,
  HOUSE_PLAN_3DK,
  HOUSE_PLAN_3LDK,
  HOUSE_PLAN_4K,
  HOUSE_PLAN_4DK,
  HOUSE_PLAN_4LDK
}

export const SORT_BY_RENT_PRICE_ASC = 1
export const SORT_BY_RENT_PRICE_DESC = 2
export const SORT_BY_AREA_DESC = 3
export const SORT_BY_BUILDING_DATE_BUILD_DESC = 4
export const SORT_BY_PROPERTY_UPDATED_AT_DESC = 5

export const LIST_PAGE_FIRST = 10
export const LIST_PAGE_CURRENT = 30
export const LIST_PAGE_OPTIONS = [LIST_PAGE_FIRST, 20, LIST_PAGE_CURRENT, 50]

export const GRID_IMAGE = 'image'
export const GRID_LIST = 'list'
export const PAGING_COUNT = 5
export const PAGING_CURRENT = 1

export const FIRST_ROOM_OPTION = 1
export const LAST_ROOM_OPTION = 13

export const ROOM_OPTIONS = [
  {
    id: HOUSE_PLAN_ONE_ROOM,
    value: FIRST_ROOM_OPTION
  },
  {
    id: HOUSE_PLAN_1K,
    value: '1K'
  },
  {
    id: HOUSE_PLAN_1DK,
    value: '1DK'
  },
  {
    id: HOUSE_PLAN_1LDK,
    value: '1LDK'
  },
  {
    id: HOUSE_PLAN_2K,
    value: '2K'
  },
  {
    id: HOUSE_PLAN_2DK,
    value: '2DK'
  },
  {
    id: HOUSE_PLAN_2LDK,
    value: '2LDK'
  },
  {
    id: HOUSE_PLAN_3K,
    value: '3K'
  },
  {
    id: HOUSE_PLAN_3DK,
    value: '3DK'
  },
  {
    id: HOUSE_PLAN_3LDK,
    value: '3LDK'
  },
  {
    id: HOUSE_PLAN_4K,
    value: '4K'
  },
  {
    id: HOUSE_PLAN_4DK,
    value: '4DK'
  },
  {
    id: HOUSE_PLAN_4LDK,
    value: LAST_ROOM_OPTION
  }
]

export const CITY_SEARCH_MODAL_TYPE = 1
export const LINE_SEARCH_MODAL_TYPE = 2
export const STATION_SEARCH_MODAL_TYPE = 3
export const LIST_SEARCH_MODAL_TYPE = 10
export const ADDRESS_LABEL_MAX_LENGTH = 30

export const SEARCH_RESOURCES = {
  link: [
    {
      rel: 'stylesheet',
      href: '/css/histogram-slider.css'
    }
  ]
}

export const SEARCH_COOKIE_NAME = 'search_criteria'
export const SEARCH_COOKIE_EXPIRED = 60 * 60 * 24 * 27 * 52

export const SORT_BY_OPTIONS = [
  {
    id: SORT_BY_RENT_PRICE_ASC,
    i18n_key: 'rent_low_to_high'
  },
  {
    id: SORT_BY_RENT_PRICE_DESC,
    i18n_key: 'rent_high_to_low'
  },
  {
    id: SORT_BY_AREA_DESC,
    i18n_key: 'area_high_to_low'
  },
  {
    id: SORT_BY_BUILDING_DATE_BUILD_DESC,
    i18n_key: 'recently_built'
  },
  {
    id: SORT_BY_PROPERTY_UPDATED_AT_DESC,
    i18n_key: 'recently_posted'
  }
]

export const PROPERTY_TYPE_LUXURY_APARTMENT = 3101
export const PROPERTY_TYPE_APARTMENT = 3102
export const PROPERTY_TYPE_DETACHED_HOUSE = 3103

export const PROPERTY_TYPES = [
  {
    id: PROPERTY_TYPE_LUXURY_APARTMENT,
    i18n_key: 'rental_apartment'
  },
  {
    id: PROPERTY_TYPE_APARTMENT,
    i18n_key: 'rental_room'
  },
  {
    id: PROPERTY_TYPE_DETACHED_HOUSE,
    i18n_key: 'rental_house'
  }
]
export const DATE_BUILDS = [
  {
    id: 0,
    i18n_key: 'no_selection'
  },
  {
    id: 1,
    i18n_key: 'newly_built'
  },
  {
    id: 3,
    i18n_key: 'within_3_years'
  },
  {
    id: 5,
    i18n_key: 'within_5_years'
  },
  {
    id: 10,
    i18n_key: 'within_10_years'
  },
  {
    id: 15,
    i18n_key: 'within_15_years'
  },
  {
    id: 20,
    i18n_key: 'within_20_years'
  },
  {
    id: 25,
    i18n_key: 'within_25_years'
  },
  {
    id: 30,
    i18n_key: 'within_30_years'
  }
]

export const WALKING_TIME_WITHIN_10_MINUTES_WALK = 10
export const WALKING_TIMES = [
  {
    id: 0,
    i18n_key: 'no_selection'
  },
  {
    id: 1,
    i18n_key: 'within_1_minute'
  },
  {
    id: 3,
    i18n_key: 'within_3_minutes'
  },
  {
    id: 5,
    i18n_key: 'within_5_minutes'
  },
  {
    id: WALKING_TIME_WITHIN_10_MINUTES_WALK,
    i18n_key: 'within_10_minutes'
  },
  {
    id: 15,
    i18n_key: 'within_15_minutes'
  },
  {
    id: 20,
    i18n_key: 'within_20_minutes'
  }
]
export const BUILDING_EQUIPMENTS = [
  { id: FACILITIES.CITY_GAS, i18n_key: 'city_gas' },
  {
    id: FACILITIES.AIR_CONDITIONING,
    i18n_key: 'air_conditioning'
  },
  { id: FACILITIES.STORAGE_ROOM, i18n_key: 'storage_room' },
  {
    id: FACILITIES.UNDERFLOOR_STORAGE,
    i18n_key: 'underfloor_storage'
  },
  { id: FACILITIES.WALK_IN_CLOSET, i18n_key: 'walk_in_closet' },
  { id: FACILITIES.LOFT, i18n_key: 'loft' },
  {
    id: FACILITIES.INDOOR_WASHING_MACHINE,
    i18n_key: 'indoor_washing_machine'
  },
  { id: FACILITIES.ELEVATOR, i18n_key: 'elevator' },
  { id: FACILITIES.PRIVATE_YARD, i18n_key: 'private_yard' },
  { id: FACILITIES.BALCONY, i18n_key: 'balcony' },
  { id: FACILITIES.FLOORING, i18n_key: 'flooring' },
  {
    id: FACILITIES.PARCEL_DELIVERY_LOCKER,
    i18n_key: 'parcel_delivery_locker'
  },
  { id: FACILITIES.HAS_BICYCLE_PARKING, i18n_key: 'parking' },
  { id: FACILITIES.BIKE_STORAGE, i18n_key: 'bike_storage' },
  { id: FACILITIES.CORNER_ROOM, i18n_key: 'corner_room' },
  { id: FACILITIES.HEATED_FLOORS, i18n_key: 'heated_floors' },
  { id: FACILITIES.DESIGNER, i18n_key: 'designer' },
  {
    id: FACILITIES.ALL_ELECTRIC_HOME,
    i18n_key: 'all_electric_home'
  },
  {
    id: FACILITIES.ALL_DAY_GARBAGE_DISPOSAL_SERVICE,
    i18n_key: '24_hour_garbage_disposal_service'
  },
  { id: FACILITIES.FULLY_FURNISHED, i18n_key: 'fully_furnished' },
  { id: FACILITIES.FREE_INTERNET, i18n_key: 'free_internet' },
  { id: FACILITIES.CATV, i18n_key: 'catv' },
  { id: FACILITIES.CLOSET, i18n_key: 'closet' },
  {
    id: FACILITIES.WITH_LIGHTING_EQUIPMENT,
    i18n_key: 'with_lighting_equipment'
  },
  { id: FACILITIES.TOP_FLOOR, i18n_key: 'top_floor' }
]
export const BATHROOM_TOILETS = [
  {
    id: FACILITIES.BATH_AND_TOILET_SEPARATE,
    i18n_key: 'bath_and_toilet_separate'
  },
  {
    id: FACILITIES.WATER_HEATING_FEATURE,
    i18n_key: 'water_heating_feature'
  },
  {
    id: FACILITIES.MAKEUP_STAND_AND_VANITY_UNIT_WITH_SHOWER,
    i18n_key: 'makeup_stand_and_vanity_unit_with_shower'
  },
  {
    id: FACILITIES.WARM_WATER_WASHING_TOILET_SEAT,
    i18n_key: 'warm_water_washing_toilet_seat'
  },
  { id: FACILITIES.BATHROOM_DRYER, i18n_key: 'bathroom_dryer' },
  {
    id: FACILITIES.INDEPENDENT_SINK,
    i18n_key: 'independent_sink'
  },
  { id: FACILITIES.BATHROOM_TV, i18n_key: 'bathroom_tv' },
  { id: FACILITIES.AUTO_BATH, i18n_key: 'auto_bath' }
]
export const KITCHENS = [
  {
    id: FACILITIES.GAS_STOVE_INSTALLED,
    i18n_key: 'gas_stove_installed'
  },
  { id: FACILITIES.IH_STOVE, i18n_key: 'ih_stove' },
  {
    id: FACILITIES.TWO_OR_MORE_STOVE_ELEMENTS,
    i18n_key: '2_or_more_stove_elements'
  },
  {
    id: FACILITIES.BUILT_IN_KITCHEN,
    i18n_key: 'built_in_kitchen'
  },
  { id: FACILITIES.REFRIGERATOR, i18n_key: 'refrigerator' },
  { id: FACILITIES.KITCHEN_COUNTER, i18n_key: 'kitchen_counter' },
  {
    id: FACILITIES.DISHWASHER_AND_DRYER,
    i18n_key: 'dishwasher_and_dryer'
  }
]
export const SECURITIES = [
  { id: FACILITIES.AUTOLOCK, i18n_key: 'autolock' },
  {
    id: FACILITIES.TV_DOOR_INTERCOM,
    i18n_key: 'tv_door_intercom'
  },
  {
    id: FACILITIES.CLOSED_CIRCUIT_TELEVISION,
    i18n_key: 'closed_circuit_television'
  }
]
export const OTHERS = [
  {
    id: FACILITIES.MUSICAL_INSTRUMENTS_NEGOTIABLE,
    i18n_key: 'musical_instruments_negotiable'
  },
  {
    id: FACILITIES.TWO_PEOPLE_ALLOWED,
    i18n_key: 'two_people_allowed'
  },
  { id: FACILITIES.WOMEN_ONLY, i18n_key: 'women_only' },
  { id: FACILITIES.STUDENT, i18n_key: 'student' },
  { id: FACILITIES.ELDERLY_WELCOME, i18n_key: 'elderly_welcome' },
  { id: FACILITIES.PETS_NEGOTIABLE, i18n_key: 'pets_negotiable' },
  {
    id: FACILITIES.NO_GUARANTOR_NEEDED,
    i18n_key: 'no_guarantor_needed_1'
  },
  { id: FACILITIES.OTHERS_CATV, i18n_key: 'catv' },
  { id: FACILITIES.CS_ANTENNA, i18n_key: 'cs_antenna' },
  { id: FACILITIES.BS_ANTENNA, i18n_key: 'bs_antenna' },
  { id: FACILITIES.OPTICAL_FIBRE, i18n_key: 'optical_fibre' },
  { id: FACILITIES.OTHER_INTERNET, i18n_key: 'other_internet' },
  {
    id: FACILITIES.TWO_FAMILY_HOUSE,
    i18n_key: 'two_family_house'
  },
  {
    id: FACILITIES.UNIVERSAL_DESIGN,
    i18n_key: 'universal_design'
  },
  {
    id: FACILITIES.RENT_INITIAL_FEE_PAYMENT_BY_CREDIT_CARD,
    i18n_key: 'rent_initial_fee_payment_by_credit_card'
  }
]
