<template>
  <div>
    <NotFound v-if="error.statusCode === 404"></NotFound>
    <h1 v-else>{{ error.message }}</h1>
    <!-- <nuxt-link to="/">Home page</nuxt-link> -->
  </div>
</template>

<script>
import NotFound from '@/components/common/NotFound.vue'
export default {
  components: {
    NotFound
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['error']
}
</script>
