<template>
  <div class="modal-overlay" @click="$emit('close', $event)">
    <div class="languages-modal languages-modal-outer">
      <div class="languages-modal-inner">
        <button class="btn-close" @click="$emit('close', $event)">
          <img v-lazy-load data-src="~assets/img/ic-close-modal@2x.png" />
        </button>
        <div class="modal-body" @click.stop>
          <ul>
            <template v-for="locale in $i18n.locales">
              <li :key="locale.code" @click="$emit('change', locale.code)">
                {{ locale.name }}
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguagesModal'
}
</script>

<style lang="scss">
.languages-modal {
  &.languages-modal-outer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .languages-modal-inner {
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    width: 374px;
    &.w-720 {
      width: 720px;
    }
  }
  .modal-body {
    padding: 22px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        text-align: center;
        padding: 9px;
        border: 1px solid #222222;
        border-radius: 4px;
        margin-bottom: 8px;
        color: #222222;
        font-weight: 500;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  button {
    display: inline-block;
    text-align: center;
    outline: none;
    border: none;
    background: transparent;
    position: absolute;
    padding: 0;
    top: -70px;
    right: 0;
  }
  @media screen and (max-width: 768px) {
    &.languages-modal-outer {
      padding: 0px 18px;
    }
    .languages-modal-inner {
      width: 100%;
    }
  }
}
</style>
