<template>
  <div class="item">
    <div class="item-right">
      <div class="item-right__body">
        <div>
          <PropertyItemImagePreview
            :property="property"
          ></PropertyItemImagePreview>
          <PropertyItemAtrribute :property="property"></PropertyItemAtrribute>
        </div>
        <nuxt-link
          class="item-button"
          :to="
            localePath({
              name: 'property-id',
              params: {
                id: property.id
              }
            })
          "
          ><button>{{ $t('see_details') }}</button>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import PropertyItemAtrribute from './PropertyItemAtrribute.vue'
import PropertyItemImagePreview from './PropertyItemImagePreview'

export default {
  name: 'PropertyItem',
  components: {
    PropertyItemImagePreview,
    PropertyItemAtrribute
  },
  props: {
    property: {
      type: Object,
      required: true
    }
  }
}
</script>
