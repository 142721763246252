export const THEME_LIVING_ALONE_PRICE_TO = 100000 // 100.000 yen
export const THEME_NEWLYWED_COUPLE_PRICE_TO = 130000 // 130.000 yen

// 敷金/礼金ゼロ
export const THEME_NO_DEPOSIT_KEY_MONEY = {
  id: 1,
  i18n_key: 'no_security_depositkey_money'
}

// 保証人不要
export const THEME_NO_GUARANTOR = {
  id: 2,
  i18n_key: 'no_guarantor_needed'
}

// 家具家電付き
export const THEME_HAS_FURNITURE = {
  id: 3,
  i18n_key: 'furnished'
}

// 一人暮らし
export const THEME_LIVING_ALONE = {
  id: 4,
  i18n_key: 'single_person_apartment'
}

// 学生・留学生
export const THEME_STUDENT = {
  id: 5,
  i18n_key: 'student_international_student'
}

// ファミリー物件
export const THEME_FAMILY_PROPERTY = {
  id: 6,
  i18n_key: 'family_apartment'
}

// 新婚・カップル
export const THEME_NEWLYWED_COUPLE = {
  id: 7,
  i18n_key: 'newlyweds_couple'
}

// カード決済
export const THEME_CREDIT_CARD_PAYMENT = {
  id: 8,
  i18n_key: 'credit_card_payment'
}

// ペット可・相談
export const THEME_PET_ALLOWED = {
  id: 9,
  i18n_key: 'pets_allowed_negotiable'
}

// デザイナーズ
export const THEME_DESIGNERS = {
  id: 10,
  i18n_key: 'designer'
}

// リノベーション
export const THEME_RENOVATION = {
  id: 11,
  i18n_key: 'renovated'
}

// セキュリティ
export const THEME_SECURITY = {
  id: 12,
  i18n_key: 'security'
}

// 駅徒歩10分以内
export const THEME_WITHIN_10_MINUTES_WALK_FROM_STATION = {
  id: 13,
  i18n_key: 'within_10_minute_walk_from_the_station'
}

// バイク置き場
export const THEME_HAS_MOTORCYCLE_PARKING = {
  id: 14,
  i18n_key: 'bicycle_storage'
}

// 駐輪場有り
export const THEME_HAS_BICYCLE_PARKING = {
  id: 15,
  i18n_key: 'bike_parking'
}

// 駐車場有り
export const THEME_HAS_CAR_PARKING = {
  id: 16,
  i18n_key: 'car_parking'
}

export const THEME_SETTING = [
  THEME_NO_DEPOSIT_KEY_MONEY,
  THEME_NO_GUARANTOR,
  THEME_HAS_FURNITURE,
  THEME_LIVING_ALONE,
  THEME_STUDENT,
  THEME_FAMILY_PROPERTY,
  THEME_NEWLYWED_COUPLE,
  THEME_CREDIT_CARD_PAYMENT,
  THEME_PET_ALLOWED,
  THEME_DESIGNERS,
  THEME_RENOVATION,
  THEME_SECURITY,
  THEME_WITHIN_10_MINUTES_WALK_FROM_STATION,
  THEME_HAS_MOTORCYCLE_PARKING,
  THEME_HAS_BICYCLE_PARKING,
  THEME_HAS_CAR_PARKING
]
