<template>
  <div :class="'app-' + langLabel" class="wrapper">
    <AppYjNav />
    <AppNav />
    <nuxt />
    <div class="footer-base">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="footer-logo">
              <img v-lazy-load data-src="~assets/img/footer-logo@2x.png" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="txt-copyright">
              {{ YOLO_HOME_COPY_RIGHT }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppYjNav from '@/components/layout/AppYjNav'
import AppNav from '@/components/layout/AppNavSimple'
import { YOLO_HOME_COPY_RIGHT } from '@/constants/config'

export default {
  components: { AppYjNav, AppNav },
  data() {
    return { YOLO_HOME_COPY_RIGHT }
  },
  computed: {
    langLabel() {
      return this.$i18n.locale
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-base {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  background-color: #2b4964;
  .txt-copyright {
    text-align: right;
    font-size: 14px;
    color: #ffffff;
  }
}
@media screen and (max-width: 768px) {
  .footer-base {
    text-align: center;
    padding: 36px 0px 41px;
    .txt-copyright {
      text-align: center;
    }
  }
}
</style>
