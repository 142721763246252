<template>
  <!-- eslint-disable vue/no-v-html -->
  <footer class="yl-footer">
    <div class="footer-row d-none">
      <div class="footer-logo">
        <img v-lazy-load data-src="~assets/img/footer-logo@2x.png" :alt="$t('headMeta.title')" />
      </div>
      <div class="txt-copyright">{{ YOLO_HOME_COPY_RIGHT }}</div>
    </div>
    <div class="yl-footer__top">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="footer-logo">
              <img
                v-lazy-load data-src="~assets/img/footer-logo@2x.png"
                :alt="$t('headMeta.title')"
              />
            </div>
            <div class="footer-icon">
              <a :href="YOLO_JAPAN_FACEBOOK_URL" target="_blank">
                <img v-lazy-load data-src="~assets/img/sns/fb.webp" alt="YOLO JAPAN Facebook" />
              </a>
              <a :href="YOLO_JAPAN_INSTAGRAM_URL" target="_blank">
                <img v-lazy-load data-src="~assets/img/sns/ig.webp" alt="YOLO JAPAN Instagram" />
              </a>
              <a :href="YOLO_JAPAN_LINE_URL" target="_blank">
                <img v-lazy-load data-src="~assets/img/sns/LINE.webp" alt="YOLO JAPAN LINE" />
              </a>
              <a :href="YOLO_JAPAN_TIKTOK_URL" target="_blank">
                <img
                  v-lazy-load data-src="~assets/img/sns/tiktok.webp"
                  alt="YOLO JAPAN TikTok"
                />
              </a>
              <a :href="YOLO_JAPAN_TWITTER_URL" target="_blank">
                <img
                  v-lazy-load data-src="~assets/img/sns/twitter.webp"
                  alt="YOLO JAPAN Twitter"
                />
              </a>
              <a :href="YOLO_JAPAN_YOUTUBE_URL" target="_blank">
                <img
                  v-lazy-load data-src="~assets/img/sns/youtube.webp"
                  alt="YOLO JAPAN Youtube"
                />
              </a>
            </div>
            <div class="footer-lang">
              <div>
                <template v-for="(locale, index) in $i18n.locales">
                  <nuxt-link
                    v-if="index < 3"
                    :key="locale.code"
                    :to="switchLocalePath(locale.code)"
                    >{{ locale.name }}</nuxt-link
                  >
                </template>
              </div>
              <div>
                <template v-for="(locale, index) in $i18n.locales">
                  <nuxt-link
                    v-if="index >= 3"
                    :key="locale.code"
                    :to="switchLocalePath(locale.code)"
                    >{{ locale.name }}</nuxt-link
                  >
                </template>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <ul class="footer-nav">
              <li>
                <nuxt-link :to="localePath('/all')">{{
                  $t('rental_units_nationwide')
                }}</nuxt-link>
              </li>
              <li class="mb-disabled">
                <nuxt-link :to="localePath('/news')">{{
                  $t('all_news')
                }}</nuxt-link>
              </li>
              <li class="pc-disabled">
                <nuxt-link :to="localePath('/flow')">{{
                  $t('steps_to_moving_in')
                }}</nuxt-link>
              </li>
              <li class="mb-disabled">
                <nuxt-link :to="localePath('/faq')">{{
                  $t('frequently_asked_questions')
                }}</nuxt-link>
              </li>
              <li class="pc-disabled">
                <nuxt-link :to="localePath('/news')">{{
                  $t('all_news')
                }}</nuxt-link>
              </li>
              <li class="pc-disabled">
                <nuxt-link :to="localePath('/faq')">FAQ</nuxt-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-3">
            <ul class="footer-nav">
              <li>
                <nuxt-link :to="localePath('/terms')">{{
                  $t('terms_of_use')
                }}</nuxt-link>
              </li>
              <li>
                <a
                  :href="
                    `${YOLO_JAPAN_PRIVACY_POLICY_URL}${
                      $i18n.locale === 'ja' ? '' : '_' + $i18n.locale
                    }`
                  "
                  target="_blank"
                  >{{ $t('privacy_policy') }}</a
                >
              </li>
              <li>
                <nuxt-link :to="localePath('/sitemap')">{{
                  $t('sitemap')
                }}</nuxt-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-2">
            <ul class="footer-nav">
              <li>
                <a :href="`${YOLO_JAPAN_URL}/${$i18n.locale}/`" target="_blank"
                  >YOLO JAPAN</a
                >
              </li>
              <li>
                <a :href="`${YOLO_BASE_URL}/${$i18n.locale}/`" target="_blank"
                  >YOLO BASE</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div
              class="footer-text"
              v-html="$options.filters.stripTags($t('yolohome_footer_summary'))"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="yl-footer__copyright">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <img v-lazy-load data-src="~assets/img/footer-logo-other@2x.png" alt="YOLO JAPAN" />
            <a target="_blank" :href="`${YOLO_JAPAN_URL}/${$i18n.locale}/`">{{
              $t('job_listing_site_for_foreigners_in_japan')
            }}</a>
          </div>
          <div class="col-lg-6">
            <span>{{ YOLO_HOME_COPY_RIGHT }}</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import * as EXTERNAL_LINK from '@/constants/externalLink'
import { YOLO_HOME_COPY_RIGHT } from '@/constants/config'

export default {
  data() {
    return {
      YOLO_HOME_COPY_RIGHT,
      ...EXTERNAL_LINK
    }
  }
}
</script>
<style lang="scss">
.yl-footer {
  &__top {
    background-color: #2b4964;
    padding-top: 48px;
    padding-bottom: 40px;
  }
  &__copyright {
    background-color: #ffffff;
    padding-top: 20px;
    padding-bottom: 16px;
    a {
      font-size: 14px;
      font-weight: 500;
      color: #222222;
      text-decoration: underline;
      display: block;
      margin-top: 9px;
    }
    span {
      display: block;
      text-align: right;
      font-size: 14px;
      font-weight: 500;
      color: #2b4964;
    }
  }
  .footer-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .txt-copyright {
    font-size: 14px;
    color: #ffffff;
  }
  .footer-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 40px;
    a {
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
    }
    .pc-disabled {
      display: none;
    }
  }
  .footer-text {
    color: #ffffff;
    font-size: 14px;
    line-height: 24px;
    border-top: 2px solid rgba($color: #ffffff, $alpha: 0.43);
    margin-top: 44px;
    padding-top: 44px;
  }
  .footer-icon {
    margin-top: 34px;
    a {
      margin-right: 12px;
      img {
        width: 35px;
      }
    }
  }
  .footer-lang {
    margin-top: 34px;
    line-height: 35px;
    a {
      font-size: 15px;
      color: #ffffff;
      &::after {
        content: '/';
        color: #3f566a;
        margin: 0 10px;
      }
    }
    a:nth-child(3n) {
      &::after {
        content: none;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .footer-row {
      padding: 36px 0;
    }
    &__top {
      padding-bottom: 0;
    }
    &__copyright {
      a {
        font-size: 12px;
        margin-bottom: 8px;
      }
      span {
        font-size: 11px;
        text-align: left;
      }
    }
    .footer-row {
      display: block;
      text-align: center;
    }
    .footer-logo {
      margin-bottom: 5px;
      img {
        width: 50%;
      }
    }
    .footer-lang {
      margin-bottom: 44px;
    }
    .footer-text {
      display: none;
    }
    .footer-icon {
      margin-top: 25px;
      margin-bottom: 39px;
    }
    .footer-nav {
      padding-bottom: 26px;
      margin-bottom: 27px;
      border-bottom: 1px solid #3f566a;
      .mb-disabled {
        display: none;
      }
      .pc-disabled {
        display: block;
      }
    }
  }
}
</style>
