<template>
  <div class="item-img" @click="redirectToPropertyDetail(property.id)">
    <div class="img-preview">
      <img v-if="property.outdoor_image" v-lazy-load :data-src="property.outdoor_image.url" />
      <img v-else v-lazy-load data-src="~assets/img/no-image@2x.jpg" />
    </div>
    <div class="img-preview">
      <img v-if="property.floor_image" v-lazy-load :data-src="property.floor_image.url" />
      <img v-else v-lazy-load data-src="~assets/img/no-image@2x.jpg" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  methods: {
    redirectToPropertyDetail(id) {
      this.$router.push(
        this.localePath({
          name: 'property-id',
          params: {
            id
          }
        })
      )
    }
  }
}
</script>
