<template>
  <section class="section browsing-history">
    <Loader v-if="isLoadingSeenProperties"></Loader>
    <div v-else class="container">
      <div v-if="!isLoadingSeenProperties && seenProperties.length" class="row">
        <div class="col-6">
          <div class="section-title">
            <div class="headTitle fs-26 mb-24">
              {{ $t('your_browsing_history') }}
            </div>
          </div>
        </div>
        <div class="col-6 text-right">
          <nuxt-link class="txt-link" :to="localePath('/history')">{{
            $t('see_more')
          }}</nuxt-link>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="section-body">
            <div class="list-row">
              <PropertyItem
                v-for="property in seenProperties"
                :key="property.id"
                :property="property"
              ></PropertyItem>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loader from '@/components/common/Loader'
import { PROPERTY_SUGGEST_LIMIT } from '@/constants/config'
import PropertyItem from '@/components/common/property/PropertyItem'

export default {
  name: 'BrowsingHistoryList',
  components: {
    Loader,
    PropertyItem
  },
  data() {
    return {
      seenProperties: [],
      isLoadingSeenProperties: false
    }
  },

  created() {
    this.getSeenProperties()
  },
  methods: {
    getSeenProperties() {
      this.isLoadingSeenProperties = true
      const seenProperties = this.$cookies.get('seen-history') || []

      this.$services.favorite
        .getSeenHistory(seenProperties)
        .then((res) => {
          this.seenProperties = res.data.slice(0, PROPERTY_SUGGEST_LIMIT)
        })
        .finally((res) => (this.isLoadingSeenProperties = false))
    }
  }
}
</script>
