const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['seamless'] = require('../middleware/seamless.js')
middleware['seamless'] = middleware['seamless'].default || middleware['seamless']

export default middleware
