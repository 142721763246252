const initialState = {
  userInfo: {
    living_japan: true,
    family_name: '',
    given_name: '',
    middle_name: '',
    family_name_ruby: '',
    given_name_ruby: '',
    middle_name_ruby: '',
    birth: '',
    prefecture_id: '',
    nationalities: [],
    gender: 'male',
    email: '',
    password: '',
    locale: ''
  },

  confirmUserInfo: {}
}

export const state = () => JSON.parse(JSON.stringify(initialState))

export const mutations = {
  SET_USER_INFO: (state, payload) => {
    state.userInfo = payload
  },
  SET_CONFIRM_USER_INFO: (state, payload) => {
    state.confirmUserInfo = payload
  }
}
