export default ($axios, app) => ({
  async getLineData(prefId) {
    const lines = await this.getLines(prefId).then((res) => {
      return res.data
    })
    const lineParams = lines.map((v) => v.line_id).join(',')
    const linePropertyTotal = await this.getLinePropertyTotal(
      prefId,
      lineParams
    ).then((res) => {
      return res.data
    })
    const lineCompanies = this.setCompanyGroup(lines, linePropertyTotal)

    return {
      lines,
      lineCompanies,
      linePropertyTotal
    }
  },

  async getLines(prefId) {
    return await $axios.get(`/lines/${prefId}`)
  },

  async getLinePropertyTotal(prefId, lines) {
    return await $axios.get(`/properties/${prefId}/line/total?lines=${lines}`)
  },

  setCompanyGroup(lines, propertyTotal) {
    const companies = []

    for (const key in lines) {
      const line = lines[key]
      const companyId = line.company_id
      const companyIndex = companies.findIndex(
        (company) => company.id === companyId
      )
      line.id = line.line_id
      line.name = line.line_name
      line.en = line.line_en

      const lineIndex = propertyTotal.findIndex((v) => v.id === line.line_id)
      line.total = 0
      if (lineIndex > -1) {
        line.total = propertyTotal[lineIndex].total
      }

      if (companyIndex > -1) {
        companies[companyIndex].lines.push(line)
        companies[companyIndex].total += line.total
        companies[companyIndex].lineIds.push(line.line_id)
      } else {
        const company = {
          id: companyId,
          name: line.company_name,
          en: line.company_en,
          total: line.total,
          lines: [line],
          lineIds: [line.line_id]
        }
        companies.push(company)
      }
    }

    return companies
  },

  getLineStationByMunicipalityCodes(municipalityCodes) {
    return $axios.$get(
      `/lines/search-by-municipality-code?municipality_codes=${municipalityCodes.join(
        ','
      )}`
    )
  }
})
