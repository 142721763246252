import addressService from './addressService'
import authService from './authService'
import contactService from './contactService'
import facebookGraphService from './facebookGraphService'
import favoriteService from './favoriteService'
import googleMapService from './googleMapService'
import lineService from './lineService'
import listService from './listService'
import masterDataService from './masterDataService'
import newsService from './newsService'
import prefService from './prefService'
import propertyService from './propertyService'
import searchService from './searchService'
import stationService from './stationService'
import userService from './userService'

export default ($axios, app) => ({
  masterData: masterDataService($axios),
  auth: authService($axios, app.$yjAxios),
  contact: contactService($axios),
  news: newsService($axios),
  facebookGraph: facebookGraphService($axios),
  property: propertyService($axios),
  search: searchService($axios),
  pref: prefService($axios, app),
  list: listService($axios, app),
  line: lineService($axios, app),
  station: stationService($axios, app),
  favorite: favoriteService($axios, app),
  user: userService($axios),
  googleMap: googleMapService($axios),
  address: addressService($axios)
})
