const initialState = {
  totalFavorites: 0
}

export const state = () => ({ ...initialState })

export const mutations = {
  UPDATE_TOTAL_FAVORITES: (state, payload) => {
    state.totalFavorites = payload.total
  }
}
