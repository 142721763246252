import { forEach } from 'lodash'

export default class BaseEntity {
  constructor(attributes) {
    this.from(attributes)
  }

  from(attributes) {
    forEach(attributes, (value, key) => {
      this[key] = value
    })
  }
}
