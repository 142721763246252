export default ($axios, app) => ({
  async getStationData(prefId, query) {
    if (query.lines === undefined) {
      return {
        lineIds: [],
        lines: [],
        stations: [],
        propertyTotal: []
      }
    }

    const lineParam = query.lines
    let lineIds = lineParam.split(',')
    lineIds = [...new Set(lineIds)]
    lineIds = lineIds.filter((v) => v !== null && v !== undefined && v.length)

    const stations = await this.getStations(prefId, lineParam).then((res) => {
      return res.data
    })

    const stationParams = stations.map((v) => v.station_id).join(',')
    const propertyTotal = await this.getStationPropertyTotal(
      prefId,
      lineParam,
      stationParams
    ).then((res) => {
      return res.data
    })

    const lines = this.setLineGroup(stations, propertyTotal)

    return {
      lineIds,
      lines,
      stations,
      propertyTotal
    }
  },
  async getStations(prefId, lines) {
    return await $axios.get(`/lines/${prefId}/stations?line_ids=${lines}`)
  },
  async getStationPropertyTotal(prefId, lines, stations) {
    return await $axios.get(
      `/properties/${prefId}/station/total?lines=${lines}&stations=${stations}`
    )
  },
  setLineGroup(stations, propertyTotal) {
    const lines = []
    for (const key in stations) {
      const station = stations[key]
      const lineId = station.line_id
      const lineIndex = lines.findIndex((line) => line.id === lineId)

      const stationKey = lineId + '-' + station.station_id
      station.key = stationKey
      station.id = station.station_id
      station.name = station.station_name
      station.en = station.station_en

      const stationIndex = propertyTotal.findIndex(
        (v) =>
          v.line_id === station.line_id && v.station_id === station.station_id
      )
      station.total = 0
      if (stationIndex > -1) {
        station.total = propertyTotal[stationIndex].total
      }

      if (lineIndex > -1) {
        lines[lineIndex].stations.push(station)
        lines[lineIndex].total += station.total
        station.total && lines[lineIndex].stationKeys.push(stationKey)
      } else {
        const line = {
          id: lineId,
          name: station.line_name,
          en: station.line_en,
          total: station.total,
          stations: [station],
          stationKeys: station.total ? [stationKey] : []
        }
        lines.push(line)
      }
    }

    return lines
  },

  getStationNamesByIDs(ids) {
    return $axios.$get(`/stations/names?station_ids=${ids.join(',')}`)
  }
})
