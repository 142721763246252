var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item-txt"},[(_vm.property.property_fee)?_c('div',{staticClass:"txt-price"},[_c('nuxt-link',{attrs:{"to":_vm.localePath({
          name: 'property-id',
          params: {
            id: _vm.property.id
          }
        })}},[_vm._v("\n      ￥"+_vm._s(_vm.$options.filters.priceFormat(_vm.property.property_fee.rent_price))+"\n      "),(_vm.property.property_fee.management_fee)?_c('span',[_vm._v(_vm._s(_vm.$t('management_fee'))+"：￥"+_vm._s(_vm.$options.filters.priceFormat(_vm.property.property_fee.management_fee)))]):_vm._e()])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"txt-info"},[_c('span',[_vm._v("\n      "+_vm._s(_vm.$t('number_floor', {
          num: _vm.property.number_floors
        }))+"\n      /")]),_vm._v(" "),_c('span',[_vm._v("\n      "+_vm._s(("" + (_vm.property.number_rooms) + (_vm.property.room_type_name)))+"\n      /\n    ")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.property.building_area)+"m²")]),_vm._v(" "),_c('span',[_vm._v(" / ")]),_vm._v(" "),(_vm.property.property_fee)?[(_vm.property.property_fee)?_c('span',[_vm._v(_vm._s(_vm.getPriceText(
            this.$t('security_deposit', _vm.property.property_fee.deposit)
          ))+"\n      ")]):_vm._e(),_vm._v(" "),_c('span',[_vm._v("/")]),_vm._v(" "),(_vm.property.property_fee)?_c('span',[_vm._v("\n        "+_vm._s(_vm.getPriceText(this.$t('key_money', _vm.property.property_fee.key_money)))+"\n      ")]):_vm._e()]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"txt-guide"},[(_vm.property.building && _vm.property.building.nearest_transportation)?_c('p',{staticClass:"mb-0"},[_c('span',[_vm._v("\n        "+_vm._s(_vm.property.building.nearest_transportation.line.name)+"\n        "+_vm._s(' ')+"\n      ")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.property.building.nearest_transportation.station.name))]),_vm._v(" "),_c('span',[_vm._v("/")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('minutes_on_foot', {
          min: _vm.property.building.nearest_transportation.walking_time
        })))])]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_c('span',[_vm._v(_vm._s(_vm.property.building.master_prefecture.name))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.property.building.address_public))]),_vm._v(" "),_c('span',[_vm._v("/")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.property.building.is_new
          ? _vm.$t('newly_built')
          : _vm.$t('built_years_ago', {
              age: _vm.property.building.age || 1
            })))]),_vm._v(" "),_c('span',[_vm._v("/")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('floor_building', { num: _vm.property.building.number_above_ground })))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }