<template>
  <section class="section search-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 p-0" style="max-width:680px">
          <div class="section__top text-center">
            <h4>{{ $t('find_a_room_on_yolo_home') }}</h4>
            <div class="sub-title">
              {{ $t('rental_units') }}
              {{ $t('results', { value: totalCountPublishedProperties }) }}
            </div>
          </div>
          <div class="section__body">
            <SearchRecentBar
              v-if="previousSearchCriteria"
              :previous-search-criteria="previousSearchCriteria"
            ></SearchRecentBar>
            <SearchByAreaBox></SearchByAreaBox>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SearchByAreaBox from '@/components/common/search/SearchByAreaBox'
import SearchRecentBar from '@/components/common/search/SearchRecentBar'

export default {
  name: 'FindRoomSession',
  components: {
    SearchByAreaBox,
    SearchRecentBar
  },
  data() {
    return {
      totalCountPublishedProperties: '〇',
      previousSearchCriteria: null
    }
  },
  created() {
    this.$services.property
      .getTotalCountPublishedProperties()
      .then((res) => (this.totalCountPublishedProperties = res))

    this.$services.list
      .getSearchCriterias()
      .then((res) => (this.previousSearchCriteria = res))
  }
}
</script>
<style lang="scss">
@import '~/assets/scss/pages/404.scss';
</style>
