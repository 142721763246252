function isUnauthorizedError(err) {
  return err.response && err.response.status === 401
}

function getResponseCookiesFromAxiosResponse(res) {
  let cookies = res.headers['set-cookie']
  cookies = typeof cookies === 'string' ? [cookies] : cookies
  return cookies || []
}

// https://github.com/microcipcip/cookie-universal/blob/3b794f4f130968ccee98f05b371bfa67822d3608/packages/cookie-universal/index.js#L26
function getResponseCookiesFromNuxtResponse(res) {
  let cookies = res.getHeader('Set-Cookie')
  cookies = typeof cookies === 'string' ? [cookies] : cookies
  return cookies || []
}

function updateTokenCookies(inResp, outResp) {
  const inCookies = getResponseCookiesFromAxiosResponse(inResp)
  const outCookies = getResponseCookiesFromNuxtResponse(outResp)
  const jwtCookie = inCookies.find((x) => x.startsWith('BEARER='))
  const refreshTokenCookie = inCookies.find((x) =>
    x.startsWith('refresh_token=')
  )

  if (!jwtCookie || !refreshTokenCookie) {
    return
  }

  outCookies.push(jwtCookie, refreshTokenCookie)
  outResp.setHeader('Set-Cookie', outCookies)

  return jwtCookie
}

export default async function({ $auth, app, store, req, res }) {
  const { data } = await app.$services.auth.loginByJwt()
  let accessToken = data.access_token || ''

  switch (data.code) {
    case 'loggedIn': {
      return
    }

    case 'logout': {
      if ($auth.loggedIn) {
        await $auth.logout()
        await app.$services.auth.deleteJwt()

        store.commit('favorite/UPDATE_TOTAL_FAVORITES', {
          total: $auth.user.total_favorites
        })
      }

      return
    }

    case 'refresh': {
      const refreshResp = await app.$services.auth.refreshJwt()

      if (process.server) {
        const jwtCookie = updateTokenCookies(refreshResp, res)
        if (jwtCookie) {
          const { data } = await app.$services.auth.refreshAccessToken(
            jwtCookie
          )
          accessToken = data.access_token
        }
      } else {
        try {
          const { data } = await app.$services.auth.refreshAccessToken()
          accessToken = data.access_token
        } catch (e) {
          if (!isUnauthorizedError(e)) {
            throw e
          }
        }
      }

      break
    }
  }

  if (!accessToken) {
    return
  }

  $auth.setToken('local', 'Bearer ' + accessToken)
  app.$axios.setHeader('Authorization', 'Bearer ' + accessToken)
  $auth.ctx.app.$axios.setHeader('Authorization', 'Bearer ' + accessToken)

  const meResp = await app.$services.auth.me()
  $auth.setUser(meResp.data)

  store.commit('favorite/UPDATE_TOTAL_FAVORITES', {
    total: $auth.user.total_favorites
  })
}
