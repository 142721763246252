<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="errorPage">
    <div class="yl-content">
      <!-- <section class="bread-crumb">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <ul>
                <li>
                  <a href="#"
                    >留学生・外国人向け不動産・賃貸情報サイトYOLO HOME</a
                  >
                </li>
                <li>
                  <a href="#">賃貸</a>
                </li>
                <li>
                  <a href="#">大阪府</a>
                </li>
                <li>
                  <a href="#">大阪市</a>
                </li>
                <li>
                  <a href="#">福島区〇〇線</a>
                </li>
                <li>
                  <a href="#">海老江駅</a>
                </li>
                <li>ラグゼ海老江II 2階</li>
              </ul>
            </div>
          </div>
        </div>
      </section> -->
      <section class="section">
        <div class="detail-unavailable">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <img v-lazy-load data-src="~assets/img/detail-unavailable@2x.png" />
                <div class="title">
                  {{ $t('the_page_you_tried_to_access_cannot_be_found') }}
                </div>
                <div
                  class="sub-title"
                  v-html="
                    $t(
                      'the_url_may_be_incorrect_or_the_page_may_have_been_deleted_we_apologize_for_the_inconvenience_but_please_check_the_address'
                    )
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FindRoomSession></FindRoomSession>
      <section class="section section-search-link">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <RecomendCriteriaPropertyType></RecomendCriteriaPropertyType>
            </div>
            <div class="col-lg-12">
              <RecomendCriteriaTheme></RecomendCriteriaTheme>
            </div>
          </div>
        </div>
      </section>
      <HomeSpecialFeature></HomeSpecialFeature>
      <BrowsingHistoryList></BrowsingHistoryList>
    </div>
  </div>
</template>
<script>
import RecomendCriteriaTheme from '@/components/common/search/RecomendCriteriaTheme'
import FindRoomSession from '@/components/common/search/FindRoomSession'
import RecomendCriteriaPropertyType from '@/components/common/search/RecomendCriteriaPropertyType.vue'
import BrowsingHistoryList from '@/components/common/search/BrowsingHistoryList'
import HomeSpecialFeature from '@/components/home/HomeSpecialFeature'

export default {
  components: {
    RecomendCriteriaTheme,
    RecomendCriteriaPropertyType,
    HomeSpecialFeature,
    BrowsingHistoryList,
    FindRoomSession
  }
}
</script>
<style lang="scss">
@import '~/assets/scss/pages/404.scss';
</style>
