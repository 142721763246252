export default ($axios) => ({
  getTopNews() {
    return $axios.get('/news/latest')
  },

  getImportantNews() {
    return $axios.get('/news/important')
  },

  getAll(perPage, page) {
    return $axios.get('/news', { params: { page_size: perPage, page } })
  },

  getDetail(id) {
    return $axios.get('/news/' + id)
  }
})
