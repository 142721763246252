<template>
  <div class="search-box h-100">
    <div v-if="totalRecentProperty > 0" class="text-notice">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        class="bell_ic"
      >
        <path
          d="M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71zM67.53 368c21.22-27.97 44.42-74.33 44.53-159.42 0-.2-.06-.38-.06-.58 0-61.86 50.14-112 112-112s112 50.14 112 112c0 .2-.06.38-.06.58.11 85.1 23.31 131.46 44.53 159.42H67.53zM224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64z"
        />
      </svg>
      <span class="ml-1">{{
        $t('total_new_units', { total: totalRecentProperty })
      }}</span>
    </div>
    <div
      class="search-box-inner h-100 d-flex flex-column flex-md-row has-cursor-pointer"
      @click="searchWithPreviousCriteria"
    >
      <template v-if="isLoading">
        <div class="h-100">
          <Loader></Loader>
        </div>
      </template>
      <template v-else>
        <span>{{ $t('search_with_previous_settings') }}</span>
        <span class="search_content d-block"
          ><div v-if="searchCriteriaText || searchCriteriaStationText">
            <span v-if="searchCriteriaText">{{ searchCriteriaText }}</span>
            <br />
            <span v-if="searchCriteriaStationText">{{
              searchCriteriaStationText
            }}</span>
          </div>
          <span v-else>{{ $t('rental_units_nationwide') }}</span>
        </span>
      </template>
    </div>
    <span class="d-block">
      <button>
        <i class="icon ic-arrow"></i></button
    ></span>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'

import {
  PROPERTY_TYPES,
  PROPERTY_TYPE_LUXURY_APARTMENT
} from '@/constants/search'

import { isEmpty, toString, parseInt } from 'lodash'

export default {
  name: 'SearchRecentBar',
  components: {
    Loader
  },
  props: {
    previousSearchCriteria: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      isLoading: false,
      totalRecentProperty: 0,
      searchCriteriaText: this.$t('rental_units_nationwide'),
      prefectureSlug: null,
      searchCriteriaStationText: ''
    }
  },
  computed: {
    redirectPatch() {
      return this.prefectureSlug ? `/${this.prefectureSlug}/list` : '/list'
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true

        if (this.previousSearchCriteria) {
          this.totalRecentProperty = await this.$services.property.getCountRecentlyProperties(
            this.previousSearchCriteria
          )
        }

        this.searchCriteriaText = await this.getPreviousSearchCriteriaLabel()
        this.searchCriteriaStationText = await this.getStationLabel()
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },

    searchWithPreviousCriteria() {
      this.$router.push(
        this.localePath({
          path: this.redirectPatch,
          query: this.previousSearchCriteria || {}
        })
      )
    },

    async getPreviousSearchCriteriaLabel() {
      if (isEmpty(this.previousSearchCriteria)) {
        return this.$t('rental_units_nationwide')
      }

      const areaLabel = await this.getAreaLabel()
      const propertyTypeLabel = this.getPropertyTypeLabel()

      return [areaLabel, propertyTypeLabel].filter((item) => !!item).join('|')
    },

    async getStationLabel() {
      const { stations: masterStationIdsStr } = this.previousSearchCriteria
      const masterStationIds = masterStationIdsStr
        ? masterStationIdsStr.split(',')
        : []

      if (masterStationIds && masterStationIds.length) {
        const stations = await this.$services.station.getStationNamesByIDs(
          masterStationIds
        )

        return stations
          .map((station) =>
            this.$i18n.locale === 'ja' ? station.name : station.en
          )
          .join('/')
      }

      return ''
    },

    getPropertyTypeLabel() {
      const { property_type_id: propertyTypesStr } = this.previousSearchCriteria
      const propertyTypes = propertyTypesStr ? propertyTypesStr.split(',') : []

      if (propertyTypes && propertyTypes.length) {
        if (propertyTypes.includes(toString(PROPERTY_TYPE_LUXURY_APARTMENT))) {
          const propertyType = PROPERTY_TYPES.find(
            (propertyType) =>
              toString(propertyType.id) ===
              toString(PROPERTY_TYPE_LUXURY_APARTMENT)
          )

          if (propertyType) return this.$t(propertyType.i18n_key)
        } else {
          const propertyType = PROPERTY_TYPES.find(
            (propertyType) => propertyType.id === parseInt(propertyTypes[0])
          )

          if (propertyType) return this.$t(propertyType.i18n_key)
        }
      }
    },

    async getAreaLabel() {
      const {
        _prefecture_id: masterPrefectureId,
        municipality_code: municipalityCodes
      } = this.previousSearchCriteria

      const prefecture = masterPrefectureId
        ? await this.getPrefectureByID(masterPrefectureId)
        : null

      const prefectureName = prefecture ? prefecture.name : ''

      const cityNames =
        prefecture && municipalityCodes && municipalityCodes.length
          ? this.getCityNames(prefecture, municipalityCodes)
          : ''

      if (prefecture) {
        this.prefectureSlug = prefecture.slug
      }

      return [cityNames, prefectureName].filter((item) => !!item).join(', ')
    },

    async getPrefectureByID(id) {
      return await this.$services.masterData.findPrefecture(
        id,
        this.$i18n.locale
      )
    },

    getCityNames(prefecture, municipalityCodes) {
      const cities = prefecture.getCities()

      return cities
        .filter((city) => municipalityCodes.includes(city.code))
        .map((city) => {
          return this.$i18n.locale === 'ja' ? city.city : city.city_en
        })
        .join('/')
    }
  }
}
</script>
