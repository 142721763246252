import { setLocaleLanguage } from '~/utils/language'

export default function({ app, store }) {
  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    if (process.browser) {
      setLocaleLanguage(newLocale)
    }

    store.commit('yj/SET_LOCALE', newLocale)
  }
}
