export default function({ $axios, $config }, inject) {
  const axios = $axios.create({
    headers: {
      common: {
        Accept: 'application/json'
      }
    }
  })
  axios.setBaseURL($config.YOLO_JAPAN_URL)
  inject('yjAxios', axios)
}
