/* eslint-disable prettier/prettier */
export const FB_SDK_VERSION = 'v2.10'
export const FB_GRAPH_API_URL = 'https://graph.facebook.com/v7.0'
export const FB_LOGIN_STATUS_CONNECTED = 'connected'

export const GOOGLE_GEOCODE_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json'

export const GOOGLE_TAG_MANAGER = {
  USER_ID_KEY: 'userId'
}

export const I18N_STORAGE_KEY = 'i18n_redirected'

export const YOLO_HOME_COPY_RIGHT = '©2020 YOLO HOME'

// swiper transition duration (in ms)
export const SWIPE_TRANSITION_DURATION = 300

export const PREFECTURE_MOST_UNITS = [13, 27, 28, 14, 40]

export const SEARCH_TYPE_AREA = 'area'
export const SEARCH_TYPE_STATION = 'station'

export const PROPERTY_FACILITY_CHOICES_ID_1ST_FLOOR = 30001
export const PROPERTY_FACILITY_CHOICES_ID_2ND_FLOOR_ABOVE = 300002
export const PROPERTY_FACILITY_CHOICES_ID_CORNER_ROM = 236001
export const PROPERTY_FACILITY_CHOICES_ID_TOP_FLOOR = 301001

export const MIN_FAVORITE_DISPLAY = 30

export const PROPERTY_MOVE_IN_DATE = {
  AS_SOON_AS_POSSIBLE: 1,
  WITHIN_3_MONTHS: 2
}

export const PREFECTURE_SEARCHABLE_IDS = [13, 14, 11, 12, 27, 26, 28, 29, 25, 30, 40, 46]

export const INQUIRY_EVENT_TYPES = {
  SAVE_INQUIRY: 1
}

export const IS_LIVING_JAPAN = 'yes'


export const MASTER_IDENTIFICATIONS = {
  MY_NUMBER_CARD: 4
}

export const PRICE_MARGIN_COMPARE = 5000
export const AREA_MARGIN_COMPARE = 5


export const PROPERTY_SUGGEST_LIMIT = 4

// property types
export const PROPERTY_TYPES = {
  LUXURY_APARTMENT: 3101,
  APARTMENT: 3102,
  DETACHED_HOUSE: 3103,
}

export const MASTER_IMAGE_CATEGORIES = {
  EXTERIOR: 2
}

export const MASTER_PARKING_CATEGORIES = {
  UNAVAILABLE: 4
}

export const ALL_PREFECTURE_PARAM = 'all'
