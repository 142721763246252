export default {
  /**
   * Build query string from params object
   *
   * @function buildQueryString
   * @param {Object} params
   * @return {string}
   */
  buildQueryString(params) {
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&')
  }
}
