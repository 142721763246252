import _pick from 'lodash/pick'
import searchUtil from '~/utils/search-util'

export default ($axios) => ({
  getPropertyCount(prefId, query) {
    const searchString = searchUtil.getSearchURLBuilder(prefId, query)
    return $axios.get(`properties/search/count?` + searchString)
  },
  async getPropertyThemes({ prefId, query }) {
    const _query = _pick(query, 'municipality_code', 'lines', 'stations')

    const searchString = searchUtil.getSearchURLBuilder(prefId, _query)

    return await $axios.get(
      `properties/search/count-by-all-themes?${searchString}`
    )
  }
})
