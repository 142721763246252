<template>
  <div class="item-txt">
    <div v-if="property.property_fee" class="txt-price">
      <nuxt-link
        :to="
          localePath({
            name: 'property-id',
            params: {
              id: property.id
            }
          })
        "
      >
        ￥{{ $options.filters.priceFormat(property.property_fee.rent_price) }}
        <span v-if="property.property_fee.management_fee"
          >{{ $t('management_fee') }}：￥{{
            $options.filters.priceFormat(property.property_fee.management_fee)
          }}</span
        ></nuxt-link
      >
    </div>
    <div class="txt-info">
      <span>
        {{
          $t('number_floor', {
            num: property.number_floors
          })
        }}
        /</span
      >
      <span>
        {{ `${property.number_rooms}${property.room_type_name}` }}
        /
      </span>
      <span>{{ property.building_area }}m²</span>
      <span> / </span>
      <template v-if="property.property_fee">
        <span v-if="property.property_fee"
          >{{
            getPriceText(
              this.$t('security_deposit', property.property_fee.deposit)
            )
          }}
        </span>
        <span>/</span>
        <span v-if="property.property_fee">
          {{
            getPriceText(this.$t('key_money', property.property_fee.key_money))
          }}
        </span>
      </template>
    </div>
    <div class="txt-guide">
      <p
        v-if="property.building && property.building.nearest_transportation"
        class="mb-0"
      >
        <span>
          {{ property.building.nearest_transportation.line.name }}
          {{ ' ' }}
        </span>
        <span>{{ property.building.nearest_transportation.station.name }}</span>
        <span>/</span>
        <span>{{
          $t('minutes_on_foot', {
            min: property.building.nearest_transportation.walking_time
          })
        }}</span>
      </p>
      <p class="mb-0">
        <span>{{ property.building.master_prefecture.name }}</span>
        <span>{{ property.building.address_public }}</span>
        <span>/</span>
        <span>{{
          property.building.is_new
            ? $t('newly_built')
            : $t('built_years_ago', {
                age: property.building.age || 1
              })
        }}</span>
        <span>/</span>
        <span>{{
          $t('floor_building', { num: property.building.number_above_ground })
        }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  methods: {
    getPriceText(priceType, priveValue) {
      return (
        priceType +
        ' ' +
        this.$t('price_yen', {
          price: this.$options.filters.priceFormat(priveValue)
        })
      )
    }
  }
}
</script>
