export default ($axios, app) => ({
  getFavorites(sort = null) {
    return $axios.get('/properties/favorites', {
      params: { sort }
    })
  },

  getSeenHistory(propertyIds, sort = null) {
    const url = app.$auth.user
      ? '/properties/auth-history'
      : '/properties/history'
    return $axios.get(url, {
      params: { ids: propertyIds, sort }
    })
  }
})
