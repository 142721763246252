export default {
  // BUILDING_EQUIPMENTS
  CITY_GAS: 20101,
  AIR_CONDITIONING: 21301,
  STORAGE_ROOM: 21401,
  UNDERFLOOR_STORAGE: 21501,
  WALK_IN_CLOSET: 21601,
  LOFT: 21701,
  INDOOR_WASHING_MACHINE: 21801,
  ELEVATOR: 22401,
  PRIVATE_YARD: 22501,
  BALCONY: 22701,
  FLOORING: 22801,
  PARCEL_DELIVERY_LOCKER: 23001,
  HAS_BICYCLE_PARKING: 23101,
  BIKE_STORAGE: 23201,
  CORNER_ROOM: 23601,
  HEATED_FLOORS: 23701,
  DESIGNER: 24301,
  ALL_ELECTRIC_HOME: 24401,
  ALL_DAY_GARBAGE_DISPOSAL_SERVICE: 25501,
  FULLY_FURNISHED: 25601,
  FREE_INTERNET: 26301,
  CATV: 26401,
  CLOSET: 26901,
  WITH_LIGHTING_EQUIPMENT: 27401,
  TOP_FLOOR: 30101,

  // BATHROOM_TOILETS
  BATH_AND_TOILET_SEPARATE: 20501,
  WATER_HEATING_FEATURE: 21101,
  MAKEUP_STAND_AND_VANITY_UNIT_WITH_SHOWER: 21201,
  WARM_WATER_WASHING_TOILET_SEAT: 24201,
  BATHROOM_DRYER: 24601,
  INDEPENDENT_SINK: 25701,
  BATHROOM_TV: 25801,
  AUTO_BATH: 27501,

  // KITCHENS
  GAS_STOVE_INSTALLED: 20501,
  IH_STOVE: 20703,
  TWO_OR_MORE_STOVE_ELEMENTS: 20802,
  BUILT_IN_KITCHEN: 20901,
  REFRIGERATOR: 22901,
  KITCHEN_COUNTER: 24501,
  DISHWASHER_AND_DRYER: 26001,

  // SECURITIES
  AUTOLOCK: 20501,
  TV_DOOR_INTERCOM: 23801,
  CLOSED_CIRCUIT_TELEVISION: 26201,

  // OTHERS
  MUSICAL_INSTRUMENTS_NEGOTIABLE: 10001,
  TWO_PEOPLE_ALLOWED: 10301,
  WOMEN_ONLY: 10402,
  STUDENT: 10702,
  ELDERLY_WELCOME: 10802,
  PETS_NEGOTIABLE: 10902,
  NO_GUARANTOR_NEEDED: 11502,
  OTHERS_CATV: 21901,
  CS_ANTENNA: 22001,
  BS_ANTENNA: 22101,
  OPTICAL_FIBRE: 23403,
  OTHER_INTERNET: 23401,
  TWO_FAMILY_HOUSE: 23901,
  UNIVERSAL_DESIGN: 24101,
  RENT_INITIAL_FEE_PAYMENT_BY_CREDIT_CARD: 26503
}
