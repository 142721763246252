<template>
  <div :class="'app-' + langLabel" class="wrapper">
    <AppYjNav />
    <AppNav />
    <nuxt />
    <AppFooter />
  </div>
</template>

<script>
import AppYjNav from '@/components/layout/AppYjNav'
import AppNav from '@/components/layout/AppNav'
import AppFooter from '@/components/layout/AppFooter'

export default {
  components: { AppYjNav, AppNav, AppFooter },
  computed: {
    langLabel() {
      return this.$i18n.locale
    }
  }
}
</script>
