<template>
  <div class="yj-nav pc-only">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="float-left">
            <a :href="topUrl"> {{ $t('yj_nav.main') }} </a>
          </div>
          <div class="float-right">
            <a :href="jobUrl" class="mr-3">
              {{ $t('yj_nav.job_search') }}
            </a>
            <a :href="surveyUrl">
              {{ $t('yj_nav.survey_search') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('yj', ['topUrl', 'jobUrl', 'surveyUrl'])
  }
}
</script>
